@media (max-width: 1315px) {
	.alist-wrap {
		/* flex-basis: 33.33%; */
		/* background: red; */
		width: calc(100% / 5);
	}
}

@media (max-width: 1073px) {
	.alist-wrap {
		/* flex-basis: 33.33%; */
		width: calc(100% / 4);
		/* background: pink; */
	}
}

@media (max-width: 800px) {
	.alist-wrap {
		/* flex-basis: 48%; */

		width: calc(100% / 3);
		/* background: purple; */
	}
}

@media (max-width: 555px) {
	.alist-wrap {
		/* flex-basis: 100%; */
		width: calc(100% / 2);
		/* background: blue; */
	}
}

.ground {
    /* display: none; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 100%; */
    height: 522px;
    width: 928px;
    overflow: hidden;

    /* width: 931px; */
    /* position: relative; */
}
/* .ground-old {
    display: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    height: 525px;
    width: 931px;
} */

.cc {
    /* width: 1280px; */
    /* height: 720px; */
    /* height: 526px; */
    /* width: 932px; */
    /* border: 2px solid #ccc; */
    background: #fff;
    overflow: hidden;
}
.cc > div {
    width: 928px;
    height: 522px;
}
/* .cc-old {
    height: 100%;
    width: 928px;
    outline: 2px solid #ccc;
    position: relative;
    background: #fff;
    overflow: hidden;
} */
.cc > div {
    width: 928px;
    height: 522px;
}

.ver-line-width {
    width: 1px !important;
}

.hor-line-height {
    height: 1px !important;
}

.c-f-menu {
    width: 200px;
    height: 118px;
    background: #fff;
    position: fixed;
    top: 0;
    padding: 15px 15px;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    z-index: 1;
}
.c-f-menu > div {
    margin-bottom: 20px;
    font-size: 14px;
    height: 16px;
    display: flex;
    cursor: pointer;
}
.c-f-menu > div > span svg {
    margin-right: 10px;
}

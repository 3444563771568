* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#pf-ptool {
    height: 100%;
    min-width: 460px;
}
html {
    -webkit-print-color-adjust: exact;
    /* -webkit-filter: opacity(1); */
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #d8e0e5; */
    /* background: #f1f1f1; */
}

.main {
    transition: transfrom 0.2s ease;
}

#ptool-bg,
#presentation_container {
    height: 100%;
    /* background-image: url('./assets/background.jpg'); */
    /* background-image: url(assets/bgsvg1.svg); */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADwCAMAAAD2Bf4HAAAAOVBMVEXX4eXX4uve5+7h6vDa5Ozy9vn3+Prt8vbp7vLr8PTl7PHb5uz0+Pro7vLv9Pf4+fzV4On+///X3+LtVQ0dAAAASUlEQVQY02MQZGBgYGQQAJFkQBY8kBsNMhGAzHggFxrkhUMOEEaDnGiQHQPywSErVsiDBNmgkB8I2ZB4ggxCDAzgkGMA04MSAABJ9APv6TTd1wAAAABJRU5ErkJggg==');
    background-repeat: repeat-x;
    /* background-repeat: no-repeat; */
    background-position: top center;
    background-color: #d8e0e5;
    /* background-size: contain; */
}
#presentation_container button:hover {
    border: 0;
}
.cc-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 997;
}
/**
* button remove border and background
* 1. Title bar close button
* */
body .btn-clean {
    background: none;
    border: 0;
    cursor: pointer;
}
.btn-clean:hover {
    border: 0;
}
.hide {
    display: none;
}

.cur-pointer {
    cursor: pointer;
}
.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.fixed {
    position: fixed;
}

.overflow-hidden {
    overflow: hidden;
}
.overflow-y-auto {
    overflow-y: auto;
}

button {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

select:focus {
    outline: none;
    border: 0;
}
select:active,
button:focus,
button:active {
    outline: none;
    border: 0;
    background: none;
}

/* OVER CSS */
#app_wrapper input[type='checkbox']:checked + span:before {
    content: ' ';
    border: 0;
}

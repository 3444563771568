.emailshare {
    display: table;
    padding-top: 10px;
}

.emailshare>div {
    display: flex;
    margin-top: 20px;
    padding: 0 20px 0 70px;
    /* outline: 1px solid blue; */
}

.emailshare>div>div {
    flex: 1.2;
    padding-top: 10px;
}

.emailshare>div>div:nth-child(2) {
    flex: 2;
    padding-top: 0;
}

.emailshare input,
.emailshare textarea {
    height: 100%;
    width: inherit;
    border: 0;
    background: 0;
    /* text-align: center; */
    padding: 10px;
    font-size: 12px;
    border: 1px solid #000;
    display: flex;
    width: 100%;
}

.emailshare textarea {
    height: 140px;
    resize: none;
}

.mailsharevalue {
    display: flex;
    background: #f1f1f1;
    padding: 10px 25px 10px 15px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.mailsharevalue input {
    border: 0;
}

.mailsharevalue input:focus {
    outline: none;
}

.mailsharevalue button {
    color: #05457C;
}

.mailsharevalue button,
#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    background: transparent !important;

}

#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover #presentation_container .mailsharevalue button:active {
    color: #00457c;
}

.emailshare p {
    position: absolute;
    bottom: 12px;
    left: 100px;
}

.downloadBox {
    font-size: 16px !important;
    color: #05457C;
    display: flex;
    justify-content: space-evenly;
}

.downloadBox-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 138px;
    flex-direction: column;
    gap: 34px;
}

.downloadBox-icon {
    height: 138px;
    width: 138px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editBox-width {
    width: 735px !important;
    gap: 30px !important;
    display: flex !important;
    flex-direction: column !important;
}

.pdf-download>.downloadBox-icon {
    border: 1px solid #E2E2E2;
    border-radius: 50%;
}

.downloadBox-share {
    margin: 0px !important;
    height: 227px;
    border: 1px solid #E2E2E2;
}

.emailshare-size {
    padding: 0px !important;
}

.emailshare-size>div {
    padding: 0px 30px !important;
    margin-top: 0px !important;
}

.mailsharevalue-size {
    padding: 10px 35px 12px 12px !important;
    margin-bottom: 30px !important;
    background-color: #F6F6F6 !important;
}

.mailshare-link {
    font-weight: 400 !important;
    font-size: 16px !important;
    align-items: center;
    gap: 8px;
    text-transform: capitalize !important;
}

.title-size h4 {
    margin-top: 0px !important;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-family: 'Conv_Gotham-Bold', Verdana, Arial, sans-serif;
}

.mailshare-font {
    font-size: 16px !important;
    max-width: 412px;
    overflow: hidden;
}

.downloadBox,
.mailshare-link {
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif;
}

.mailsharevalue-size>input {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif;
}

.share-header {
    font-weight: 400 !important;
}
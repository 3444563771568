.modal-open {
    overflow: hidden;
}

.editBoxWrap {
    background: rgba(0, 0, 0, .5);
    position: fixed;
    left: 0;
    width: 100%;
    /* 1151px; */
    /* 1143px; */
    /* height: 100%;     */
    min-height: 100%;
    /* 615px; */
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    overflow-y: auto;
    padding: 30px 0 30px;
}

.editBoxWrap::-webkit-scrollbar {
    width: 6px;
    padding: 4px;
}

.editBoxWrap::-webkit-scrollbar-track {
    padding: 10px;
    background: #fff;
    padding: 0 10px;
}

.editBoxWrap::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
    cursor: pointer;
}

.editscroll {
    min-height: 100px;
}

.editBox {
    width: 750px;
    background: #fff;
    height: auto;
    margin: 0 auto;
    /* display: flex; */
    /* flex-direction: column; */
    /* min-height: 90vh; */
}

.editBox>* {
    padding: 0 20px;
}

.editBoxWrap .editBox .backgroundBox__title button img {
    position: absolute;
    top: 0;
    right: 0;
}

.editBoxWrap .editBox .backgroundBox__body {
    /* min-height: 300px; */
    display: block;
    /* flex-grow: 1; */
    /* flex-shrink: 0; */
    flex: 0 0;
    /* overflow-y: auto; */
    /* overflow: overlay; */
}

.backgroundBox__body p {
    padding: 10px 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 1.3;
}
.backgroundBox__body p+p {
    padding-bottom: 20px;
}

.backgroundBox__body table {
    width: 100%;
    border: 0;
}
.backgroundBox__body input[type=checkbox] {
    opacity: 1 !important;
    height: 13px !important;
    width: 13px !important;
    position: relative;
    z-index: 1;
}

#app_wrapper .backgroundBox__body button.delete {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEwQTc4ODg1NTQ4NDExRUJCOEE2QzcxMDBFRDQ3MkIyIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEwQTc4ODg2NTQ4NDExRUJCOEE2QzcxMDBFRDQ3MkIyIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTBBNzg4ODM1NDg0MTFFQkI4QTZDNzEwMEVENDcyQjIiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTBBNzg4ODQ1NDg0MTFFQkI4QTZDNzEwMEVENDcyQjIiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4isd/QAAABBklEQVR42mzRz0sCQRTA8Z3YJH/kwTRvKtklvHixU4c6CP0PdvDk/+HZP8RrB8GTHiSDbh3Cg6IhCGqpEJRkgut34A2tugMfZve9eTNvdm3HcSyllGXGRTLZYMphAxuPg+HwweRtWXTNFJBYBiVMkEWR/K3kpkqfkE6larzc4wlhfLtOOMEPblBRpiV2+ZNkBHMkMMIZHLzRWvzI+h8LRFHHHXrIoyrxmV7kLphJYo1THCOEFWK6//2CMc5lQUhiel4ijg+vE3TBF/wSMx/As6VPSfzCJ7GgbBCTz7xTMJUTnvGufxi6eJGWPC99hZbcp4w+Org0BbaroIk0CtbheEVbP2wFGAA3DkTOj4EKigAAAABJRU5ErkJggg==
) no-repeat center center;
    height: 100%;
    width: 100%;
}

#app_wrapper .backgroundBox__body button.delete:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkJGQjU1QjdBNTQ4NDExRUI4ODAwQzdFNTUzMTY2NzNGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkJGQjU1QjdCNTQ4NDExRUI4ODAwQzdFNTUzMTY2NzNGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QkZCNTVCNzg1NDg0MTFFQjg4MDBDN0U1NTMxNjY3M0YiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QkZCNTVCNzk1NDg0MTFFQjg4MDBDN0U1NTMxNjY3M0YiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6jK0vFAAAA/0lEQVR42mzRv2vCQBTAcVNSqVYdaqKbS12Ki4tODu0g+D/o4OT/4ewf4uogdKqD2EK3DuKgiIvQpr0WClr8AcbvwQtcNQcfLnnvXu7dxfJ9P2IOpdQTUwkH2Og5jtMI8rYsKjPFJVZACx8ookn+XnKepXcg0OelhhFSWBk7XGGNCjpW0BJFO0ne4Bs5LJGGXjSmteyF0f4PHDziATNU0ZW40ovMAiWJPZK4RAJbuLr/04J3ZGRBQmJ6/kMWn2E76IJfxCQWXEBoS1+S2CAqsWv5gCvX/K/Akx1esNA/DFO8Skuhh77DUM7TxhwT5IMC2ygY4Bb1yPl4w7N+OAowANI0S+44aRVYAAAAAElFTkSuQmCC);
    background-color: orange;
    border-radius: 0;
}

#app_wrapper .backgroundBox__body button.add {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFGNDJENjkxNTQ4NDExRUI4Njg0QjdBQjc2MkNBRkE2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFGNDJENjkyNTQ4NDExRUI4Njg0QjdBQjc2MkNBRkE2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUY0MkQ2OEY1NDg0MTFFQjg2ODRCN0FCNzYyQ0FGQTYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUY0MkQ2OTA1NDg0MTFFQjg2ODRCN0FCNzYyQ0FGQTYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6tZOksAAAAoklEQVR42mL8//8/AymABUQwutWiizcCsR4QRwDxT5jg/13NDEw4DFIFYjUgZkOXwKXhB9Tkf8Rq+AXEf4D4K1Y/AEEFEEsD8W8g/gvE1kAsCsQToBrZoIaUwDTYALEu1Cl/oZrZgdgL6iyQhi/INoQDMR/UBhCeBA0lL6hfWKE2wDV8RXPvL6hNb2EKCXmaA+okDmJD6QuSLSiAkdSkARBgAFR8Kgopg2MrAAAAAElFTkSuQmCC) no-repeat center center;
    height: 100%;
    width: 100%;
}

#app_wrapper .backgroundBox__body button.add:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE5QzM1Nzg1NTQ4NDExRUJBNEU0QzUwMkVDRUMxM0JFIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE5QzM1Nzg2NTQ4NDExRUJBNEU0QzUwMkVDRUMxM0JFIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTlDMzU3ODM1NDg0MTFFQkE0RTRDNTAyRUNFQzEzQkUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTlDMzU3ODQ1NDg0MTFFQkE0RTRDNTAyRUNFQzEzQkUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5WrhVAAAAAl0lEQVR42mL8//8/AymACYd4IxCvB2J2YjWoArEaELMRq+EHEP8E4n/EavgFxH+A+Cu6BCPU0xVALA3Ev4H4LxB7ALEoEK+AamSDGlLCAtVoA8S6UKf8hWoGedgL6iyQhi/INnADMR/UBhCeBMR6UA0gv7BCbXgPs+Ermnt/QW16C2UT9DQH1EkcxIbSFyRbsIYS0QAgwABLqSz7Bm0N1gAAAABJRU5ErkJggg==);
    background-color: orange;
    border-radius: 0;
}


/* #app_wrapper .backgroundBox__body button.add:disabled { background: red; } */

.ecqEditbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.ecqEditbutton:hover {
    background: rgba(0, 0, 0, .25);
}

.ecqEditbutton button {
    display: none;
    bottom: 30px;
    /* left: 50%; */
    height: 36px;
    /* margin-left: -80px !important; */
    width: 168px;
    border-radius: 36px;
    padding: 0px;
    background: rgba(255, 255, 255, .6);
    /* display: block; */
    font-size: 14px;
    font-weight: normal;
    color: #000;
    margin: auto !important;
    position: absolute !important;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: "Conv_Gotham-Book", sans-serif !important;
}

.ecqEditbutton:hover button {
    display: inline;
}

.ecqEditbutton span {
    border-bottom: 1px #000000 dashed;
}

.ecqEditbutton button::after {
    content: '';
    width: 110px;
    height: 31px;
    border-bottom: 1px dashed #000;
    position: absolute;
    top: -3px;
    left: 31px;
    display: block;
}

.backgroundBox__body table th {
    height: 48px;
    background-color: #c7332c;
    color: #fff;
    padding: 0 10px;
    font-size: 18px;
    font-weight: 400;
    vertical-align: middle;
    text-align: center;
}

.backgroundBox__body table th:first-child {
    text-align: left;
}

.backgroundBox__body table td:first-child img {
    outline: 1px solid #c3c3c3;
}

td.tderror,
.backgroundBox__body table tbody td:nth-child(3).tderror
{
    background: rgba(255,0,0,.3);
}
.backgroundBox__body p.errorform {
    padding: 0;
    color: red;
    margin-bottom: 20px;
    line-height: 1.6em;
}

.backgroundBox__body table th.green-one {
    background-color: #0f7a75;
    font-size: 14px;
    line-height: 1.3;
}

.backgroundBox__body table th.green-two {
    background-color: #0e746f;
}


/* .backgroundBox__body table tbody{
  border: 1px #808080 solid;
  
} */

.backgroundBox__body table tbody td {
    border: 1px #808080 dashed;
    border-right: 0;
    border-bottom: 0;
    height: 29px;
    font-size: 12px;
    padding: 0;
    vertical-align: middle;
}

.backgroundBox__body table tbody td:last-child {
    border: 1px #808080 dashed;
    border-bottom: 0;
}

.backgroundBox__body table tbody span.col {
    width: 17px;
    height: 17px;
    background: red;
    display: block;
}

.backgroundBox__body table tbody td:first-child {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
    min-width: 220px;
    padding: 0 10px 0 0;
}

.backgroundBox__body table tbody td:first-child .EditableItemCell {
    display: inline-block;
}

.backgroundBox__body table tbody td:first-child img {
    float: right;
}

.backgroundBox__body table tbody td:nth-child(2) {
    background-color: #e1e1e1;
    width: 75px;
    text-align: center;
}

.backgroundBox__body table tbody td:nth-child(3) {
    background-color: #eeeeee;
    text-align: center;
    width: 74px;
}

.backgroundBox__body table tbody td:nth-child(4) {
    /* color: #fff;
    background-color: #071639; */
    color: #000;
    background-color: #e1e1e1;
    border-style: solid;
    text-align: right;
    min-width: 76px;
    padding: 0 10px;
    vertical-align: middle;
}

.backgroundBox__body table tbody td:nth-child(5),
.backgroundBox__body table tbody td:nth-child(6) {
    background-color: #fdf7b3;
    text-align: center;
    width: 74px;
}

.backgroundBox__body table tbody td:nth-child(5) .editablebox {
    background: transparent;
}

.backgroundBox__body table tbody td:nth-child(6) {
    border-style: solid;
    border: 0;
    border-top: 1px #d3d3d3 solid;
    border-left: 1px #d3d3d3 dashed;
    width: 76px;
}

.backgroundBox__body table tbody td:nth-child(7) {
    border-style: solid;
    border: 0;
    text-align: center;
    border-top: 1px #d3d3d3 solid;
    background-color: #f0eaaa;
    width: 32px;
}

.backgroundBox__body table tbody tr.tblSpace td {
    border-bottom: 1px #808080 dashed;
    border-bottom: 0;
    border-left: 0;
    height: 30px;
}

.backgroundBox__body table tbody tr.tblSpace td:first-child {
    border-bottom: 2px solid #c7332c;
}

.backgroundBox__body table tbody tr.tblSpace td:nth-child(2),
.backgroundBox__body table tbody tr.tblSpace td:nth-child(3) {
    border-bottom: 2px solid #071639;
}

.backgroundBox__body table tbody tr.totalExcVAT td {
    border: 0;
    height: 47px;
    font-size: 18px;
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.totalExcVAT td:nth-child(2) {
    text-align: right;
    padding-right: 10px;
    vertical-align: middle;
    background: #eee;
}

.backgroundBox__body table tbody tr.pricePerProduct td {
    font-size: 18px;
    border: 0;
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.pricePerProduct td:first-child {
    background: #c7332c;
    color: #fff;
    height: 48px;
}

.backgroundBox__body table tbody tr.pricePerProduct td:nth-child(2) {
    /* background: #071639;
    color: #fff; */
    background: #e1e1e1;
    color: #000;
    height: 48px;
    text-align: right;
    padding-right: 10px;
    vertical-align: middle;
}

.backgroundBox__body table tbody tr td.bordernone {
    border-right: 0;
    border-left: 0;
}

.backgroundBox__body table tbody tr td.tdwhite {
    background: #fff;
    color: #000000;
    padding: 0;
}

.backgroundBox__body table tbody tr td.tdyellow {
    background: #fdf7b3;
    border-style: dashed;
}

.backgroundBox__body table tbody tr td.tdacttion {
    background: #f0eaaa;
}

.backgroundBox__body table tbody tr td.tdgret {
    background: #eee;
    color: #000;
}

.backgroundBox__body table tbody tr.more-items td {
    padding-left: 10px;
}

.backgroundBox__body table tbody tr.more-items td:nth-child(4) {
    background-color: #eee;
    color: #000;
}

.backgroundBox__body table tbody tr.more-items td:nth-child(7) {
    padding-left: 0;
}

.EditableCell {
    height: 100%;
    width: 75px;
}

.EditableItemCell input,
.EditableCell input {
    height: 100%;
    width: inherit;
    border: 0;
    background: 0;
    text-align: center;
    font-size: 12px;
}

.EditableItemCell input {
    text-align: left;
    padding-left: 10px;
}

.EditableItemCell input:focus,
.EditableCell input:focus {
    outline: 0;
}

#app_wrapper .editBox__footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

#app_wrapper .editBox__footer .local__reset,
#app_wrapper .editBox__footer .local__reset:hover {
    /* border: 0; */
    padding: 0;
    border: 0;
    background: none;
    border: none !important;
}

.editablebox {
    height: 28px;
    background: transparent;
}

.editablebox_input,
.editablebox_input:focus {
    border: 0;
    outline: none;
    height: 100%;
    width: 64px;
    padding-left: 10px;
    text-align: center;
    background: transparent !important;
}


/* Chrome, Safari, Edge, Opera */

.editablebox_input::-webkit-outer-spin-button,
.editablebox_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

.editablebox_input[type=number] {
    -moz-appearance: textfield;
}

.editablebox .editablebox_action {
    flex-direction: column;
    width: 14px;
}

.editablebox .editablebox_action img {
    display: none;
    cursor: pointer;
}

.editablebox:hover .editablebox_action img {
    display: flex;
}


/* EDITBLE FORM */

.editableformWrap {
    background: rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

.editableform {
    /* position: absolute; */
    background-color: #fff;
    width: 400px;
    height: 420px;
    z-index: 11;
    display: block;
    flex-direction: column;
}

.editableform>div {
    padding: 0 20px;
}

.editableform .editableform__body {
    padding: 10px 20px;
    flex: 0;
}

.editableform .editableform__body .formEle {
    display: flex;
    padding-bottom: 10px;
    flex-direction: column;
}

.editableform .editableform__body label {
    width: auto;
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
}

.editableform .editableform__body input[type=text],
.editableform .editableform__body input[type=number] {
    height: 30px;
    border: 1px #ccc solid;
    margin-bottom: 5px;
}
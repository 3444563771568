#app_wrapper .button {
    cursor: pointer;
	color: #fff;
	padding: 10px 22px;
	border-radius: 17px;
	font-size: 14px;
	transition: all 0.3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
    min-width: 92px;
    height: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}
#app_wrapper .button:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #1e3968;
	border-radius: 10rem;
	z-index: -2;
}
#app_wrapper .button:before {
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	width: 0%;
	height: 100%;
	background-color: #4b6186;
	transition: all 0.3s;
	border-radius: 10rem;
	z-index: -1;
}
#app_wrapper .button:hover {
	color: #fff;
}
#app_wrapper .button:hover:before {
	width: 100%;
}

#app_wrapper .button.btn-cancel {
    color: #1e3968 ;
	padding-left: 17px;
}
#app_wrapper .button.btn-cancel:after {
    background-color: #fff;
    /* color: #000; */
}
#app_wrapper .button.btn-cancel:before {
    background-color: #efefef;;
}

#app_wrapper .outer .button .text {
    display: flex;
    align-items: center;
}

.cc-side {
    background: #fff;
    border-right: 1px #e2e2e2 solid;
    width: 88px;
    transition: width 0.3s ease;
    z-index: 2;
}
.cc-side.active {
    width: 432px;
    /* min-width: 432px; */
}

.cc-smenu {
    background: #fff;
    border-right: 1px solid #e2e2e2;
    width: 88px;
    height: 100%;
    left: 0;
    top: 0;
}
.cc-smenu > div {
    width: 88px;
    height: 88px;

    font-size: 12px;
    font-style: normal;
    font-weight: 325;
    line-height: 100%; /* 12px */
    text-align: center;
    cursor: pointer;
    z-index: 1;
}
.cc-smenu > div > span {
    width: 36px;
    height: 36px;
}

/* PANEL */
.cc-spanel {
    width: 344px;
    max-height: 100%;
    right: 0;
    height: 100%;
    overflow: hidden;
}
.cc-spwrap {
    padding: 0 30px 30px;
    height: 100%;
}
.cc-spbtn {
    width: 10px;
    height: 66px;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    right: -10px;
    z-index: 100;
    cursor: pointer;
}
.cc-spbb {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cc-spba {
    display: block;
    position: absolute;
    margin-top: 24px;
    right: 5px;
}
.cc-smbox {
    width: 88px;
    height: 84px;
    position: absolute;
    top: 0;
    border: 1px solid #fff;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    pointer-events: none;
    display: none;
    /* outline-color: red !important; */
    /* z-index: 1; */
    /* background: #e2e2e2; */
}
.cc-smbox.on {
    transition: top 0.5s ease;
    display: block;
    /* transition: display 1s ease; */
}
.cc-smbox.off {
    border: 0 solid transparent;
    transition: border-color 0.3 ease;
}

.cc-smenu > div > * {
    pointer-events: none;
}
/* .cc-smenu > div {
    outline: 1px solid #e2e2e2;
} */

/* HEIGHT */
.h-f,
.h-all {
    height: 100%;
}
.h-full {
    height: 522px;
}
.h-auto {
    height: auto;
}
.h-50 {
    height: 50px;
}
.h-525 {
    height: 525px;
}
.h-44 {
    height: 44px;
}

/* WIDTH */
.wa {
    width: auto;
}
.w-full {
    width: 100% !important;
}
.w-80 {
    width: 80%;
}
.w40 {
    width: 40px;
}
.w244 {
    width: 244px;
}
.w-half {
    width: 50%;
}

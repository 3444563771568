/*
COLOR BOX
*/

.colorBoxWrap {
    background: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
}

.colorBox {
    width: 610px;
    background: #fff;
    height: 440px;
    /* 430px; */
    display: flex;
    flex-direction: column;
    /* margin-top: -200px;
    margin-right: -145px; */
}

.colorBox > * {
    padding: 0 20px;
}

.colorBox__title {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px #f1f1f1 solid;
    height: 50px;
    line-height: 50px;
}

.colorBox__title h4 {
    font-size: 18px;
    margin-top: 10px;
    line-height: 1.5;
}

.colorBox__title button {
    /* background: red; */
    width: 16px;
    height: 16px;
    position: relative;
    right: -10px;
    top: 10px;
}

.colorBox__title img {
    width: 16px;
    position: absolute;
    top: 0;
    right: 0;
}

.colorBox__body {
    flex: 1;
    padding-top: 20px;
    padding-bottom: 6px;
}

.colorBox__footer {
    flex: 0 0 50px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    /* border-top: 1px #f1f1f1 solid; */
    align-items: center;
}

#app_wrapper .colorBox__footer button {
    color: #000;
    margin-left: 10px;
    border: 1px solid #ccc;
    height: 34px;
    padding: 0 20px;
    background: #f4f4f4;
    border-radius: 30px;
    min-width: 100px;
    font-weight: normal;
}

#app_wrapper .colorBox__footer button:hover,
#app_wrapper .colorBox__footer button:active,
#app_wrapper .colorBox__footer button:focus {
    background: #f2f2f2 !important;
    border: 1px #ccc solid !important;
}

#app_wrapper .colorBox__footer button.ok {
    background: #fff;
    text-transform: uppercase;
}

.colorset {
    min-width: 50px;
    flex: 1;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px #cbcbcb solid;
    margin-right: 15px;
    padding-right: 15px;
}

.colorset .set {
    /* display: flex; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.colorset .set span {
    display: block;
    width: 16px;
    height: 16px;
    outline: solid 1px #000;
    margin-bottom: 5px;
    cursor: pointer;
}

.colorset span {
    display: block;
    width: 32px;
    height: 22px;
    outline: solid 1px #000;
}

.photoshop-picker {
    background: none !important;
    box-shadow: none !important;
}

.photoshop-picker > div:first-child {
    display: none;
}

.photoshop-picker .flexbox-fix {
    padding: 0 !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(1) {
    outline: 1px solid blue !important;
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:first-child {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(2) {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:nth-child(3) > div:nth-child(10) {
    display: none;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div {
    /* outline: 1px dashed red; */
    width: 160px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div span {
    width: 10px !important;
    height: 34px !important;
    line-height: 34px !important;
}
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:first-child,
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:nth-child(2),
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div div:nth-child(3) {
    background: yellow;
    top: -9999em;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(5) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(6) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(7) {
    top: -122px;
    right: -85px;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) {
    /* top: -122px; */
    top: -75px;
    /* right: -85px; */
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child {
    height: 200px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div input {
    width: 44px !important;
    height: 34px !important;
    padding-left: 11px !important;
    font-size: 14px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}
.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div label {
    top: 6px !important;
}

.photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) input {
    width: 90px !important;
    height: 34px !important;
}

.pos-relative {
    position: relative;
    margin-bottom: 5px;
}
.fixcmyk {
    /* display: none; */
    position: relative;
    left: -160px;
    top: 5px;
}
.fixcmyk span {
    /* display: block; */
    position: absolute;
    text-transform: uppercase;
    left: 0px;
    top: 9px;
    font-size: 13px;
    width: 10px !important;
    height: 34px !important;
    /* outline: 1px solid red; */
}
.fixcmyk input {
    width: 44px !important;
    height: 34px !important;
    padding-left: 9px !important;
    font-size: 14px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}

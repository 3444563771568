/*@pfc-black: #231f20;*/
.icon {
    font-family: 'pfc-font-icon' !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}
.pfc-instagram-logo:before {
    content: '\e900';
    text-transform: none !important;
}
.pfc-footer-usp:before {
    content: '\61';
    text-transform: none !important;
}
.pfc-address-book:before {
    content: '\62';
    text-transform: none !important;
}
.pfc-arrow-clock:before {
    content: '\63';
    text-transform: none !important;
}
.pfc-arrow-down:before {
    content: '\64';
    text-transform: none !important;
}
.pfc-arrow-left:before {
    content: '\65';
    text-transform: none !important;
}
.pfc-arrow-right:before {
    content: '\66';
    text-transform: none !important;
}
.pfc-arrow-up:before {
    content: '\67';
    text-transform: none !important;
}
.pfc-breadcrumb-home:before {
    content: '\68';
    text-transform: none !important;
}
.pfc-calendar:before {
    content: '\69';
    text-transform: none !important;
}
.pfc-checkmark:before {
    content: '\6a';
    text-transform: none !important;
}
.pfc-close:before {
    content: '\6b';
    text-transform: none !important;
}
.pfc-delete:before {
    content: '\6c';
    text-transform: none !important;
}
.pfc-delivry-solid:before {
    content: '\6d';
    text-transform: none !important;
}
.pfc-download:before {
    content: '\6e';
    text-transform: none !important;
}
.pfc-reposition:before {
    content: '\6f';
    text-transform: none !important;
}
.pfc-refresh:before {
    content: '\70';
    text-transform: none !important;
}
.pfc-maximize:before {
    content: '\71';
    text-transform: none !important;
}
.pfc-addtext:before {
    content: '\72';
    text-transform: none !important;
}
.pfc-likendin-logo:before {
    content: '\73';
    text-transform: none !important;
}
.pfc-info-icon:before {
    content: '\74';
    text-transform: none !important;
}
.pfc-image-icon:before {
    content: '\75';
    text-transform: none !important;
}
.pfc-arrow-up-thing:before {
    content: '\76';
    text-transform: none !important;
}
.pfc-arrow-left-thing:before {
    content: '\77';
    text-transform: none !important;
}
.pfc-arrow-down-thing:before {
    content: '\78';
    text-transform: none !important;
}
.pfc-arrow-right-thing:before {
    content: '\79';
    text-transform: none !important;
}
.pfc-euro:before {
    content: '\7a';
    text-transform: none !important;
}
.pfc-edit:before {
    content: '\41';
    text-transform: none !important;
}
.pfc-rotate:before {
    content: '\42';
    text-transform: none !important;
}
.pfc-mail:before {
    content: '\43';
    text-transform: none !important;
}
.pfc-mainsearch-icon:before {
    content: '\44';
    text-transform: none !important;
}
.pfc-minus:before {
    content: '\45';
    text-transform: none !important;
}
.pfc-no-decoration:before {
    content: '\46';
    text-transform: none !important;
}
.pfc-notes:before {
    content: '\47';
    text-transform: none !important;
}
.pfc-magnifying-glass:before {
    content: '\48';
    text-transform: none !important;
}
.pfc-phone:before {
    content: '\49';
    text-transform: none !important;
}
.pfc-pinpoint-line:before {
    content: '\4a';
    text-transform: none !important;
}
.pfc-pinpoint-solid:before {
    content: '\4b';
    text-transform: none !important;
}
.pfc-plus:before {
    content: '\4c';
    text-transform: none !important;
}
.pfc-shipping-icon:before {
    content: '\4d';
    text-transform: none !important;
}
.pfc-shopping-cart:before {
    content: '\4e';
    text-transform: none !important;
}
.pfc-youtube-logo:before {
    content: '\4f';
    text-transform: none !important;
}
.pfc-warning:before {
    content: '\50';
    text-transform: none !important;
}
.pfc-vimeo-logo:before {
    content: '\51';
    text-transform: none !important;
}
.pfc-view:before {
    content: '\52';
    text-transform: none !important;
}
.pfc-reports:before {
    content: '\53';
    text-transform: none !important;
}
.pfc-user-icon:before {
    content: '\54';
    text-transform: none !important;
}
.pfc-upload-visual:before {
    content: '\55';
    text-transform: none !important;
}
.pfc-twitter-logo:before {
    content: '\56';
    text-transform: none !important;
}
.pfc-print:before {
    content: '\57';
    text-transform: none !important;
}
.pfc-express:before {
    content: '\58';
    text-transform: none !important;
}
.pfc-shoppigcartplus:before {
    content: '\59';
    text-transform: none !important;
}
.pfc-trust:before {
    content: '\5a';
    text-transform: none !important;
}
.pfc-book:before {
    content: '\30';
    text-transform: none !important;
}
.pfc-zoom:before {
    content: '\31';
    text-transform: none !important;
}
.pfc-cog:before {
    content: '\32';
    text-transform: none !important;
}
.pfc-embroidery:before {
    content: '\33';
    text-transform: none !important;
}
.pfc-get-quote:before {
    content: '\34';
    text-transform: none !important;
}
.pfc-hxd:before {
    content: '\35';
    text-transform: none !important;
}
.pfc-padprint:before {
    content: '\36';
    text-transform: none !important;
}
.pfc-resize:before {
    content: '\37';
    text-transform: none !important;
}
.pfc-resize-horizontal:before {
    content: '\38';
    text-transform: none !important;
}
.pfc-cross-remove:before {
    content: '\39';
    text-transform: none !important;
}
.pfc-circle:before {
    content: '\21';
}
.pfc-diameter:before {
    content: '\23';
}
.pfc-check-circle:before {
    content: '\22';
}
.pfc-paint-brush:before {
    content: '\24';
}
.pfc-calendar-empty:before {
    content: '\25';
}
.pfc-factory:before {
    content: '\26';
}
.pfc-check-outline:before {
    content: '\27';
}
.pfc-bookmarks:before {
    content: '\2a';
}
.pfc-power:before {
    content: '\3a';
}
.pfc-transactoions:before {
    content: '\2e';
}
.pfc-user:before {
    content: '\2f';
}
.pfc-cart:before {
    content: '\28';
}
.pfc-new:before {
    content: '\29';
}
.pfc-sale:before {
    content: '\2b';
}
.pfc-information:before {
    content: '\2c';
}
.pfc-presentation:before {
    content: '\e901';
    text-transform: none !important;
}
.pfc-gear:before {
    content: '\e902';
    text-transform: none !important;
}
.pfc-overview:before {
    content: '\e903';
    text-transform: none !important;
}
.pfc-profile-settings:before {
    content: '\e905';
    text-transform: none !important;
}
.pfc-dashboard:before {
    content: '\e90c';
    text-transform: none !important;
}
.pfc-invoice:before {
    content: '\e90b';
    text-transform: none !important;
}
.pfc-mail-new:before {
    content: '\e90a';
    text-transform: none !important;
}
.pfc-order:before {
    content: '\e909';
    text-transform: none !important;
}
.pfc-pass:before {
    content: '\e904';
    text-transform: none !important;
}
.pfc-user-manage:before {
    content: '\e908';
    text-transform: none !important;
}
.pfc-config:before {
    content: '\e90d';
    text-transform: none !important;
}
.pfc-collection-new:before {
    content: '\e906';
    text-transform: none !important;
}
.pfc-save:before {
    content: '\e907';
    text-transform: none !important;
}
.pfc-collection:before {
    content: '\e90e';
    text-transform: none !important;
}
.pfc-category:before {
    content: '\e90f';
    text-transform: none !important;
}
.pfc-bestseller:before {
    content: '\e910';
    text-transform: none !important;
}
.pfc-chart:before {
    content: '\e911';
    text-transform: none !important;
}
.pfc-hidden:before {
    content: '\e914';
    text-transform: none !important;
}
.pfc-bag:before {
    content: '\e912';
    text-transform: none !important;
}
.pfc-sample:before {
    content: '\e913';
    text-transform: none !important;
}
.pfc-new-nav:before {
    content: '\e915';
    text-transform: none !important;
}
.pfc-sale-nav:before {
    content: '\e916';
    text-transform: none !important;
}
.pfc-bestsellers:before {
    content: '\e917';
    text-transform: none !important;
}
.pfc-green-points:before {
    content: '\e91b';
    text-transform: none !important;
}
.pfc-delete-new:before {
    content: '\e91a';
    text-transform: none !important;
}
.pfc-arrow-up-full:before {
    content: '\e918';
    text-transform: none !important;
}
.pfc-upload-alt:before {
    content: '\e919';
    text-transform: none !important;
}
.pfc-brand:before {
    content: '\e91d';
    text-transform: none !important;
}
.pfc-product:before {
    content: '\e91c';
    text-transform: none !important;
}
.pfc-brand:before {
    content: '\e91d';
    text-transform: none !important;
}
.pfc-product:before {
    content: '\e91c';
    text-transform: none !important;
}
.pfc-download-new:before {
    content: '\e91e';
    text-transform: none !important;
}
.pfc-address-book-cart:before {
    content: '\e91f';
    text-transform: none !important;
}
.pfc-dyson-video:before {
    content: '\e920';
    text-transform: none !important;
}
.pfc-dyson-catalogue:before {
    content: '\e921';
    text-transform: none !important;
}
.pfc-dyson-cart:before {
    content: '\e922';
    text-transform: none !important;
}
.pfc-dyson-image-bold:before {
    content: '\e923';
    text-transform: none !important;
}
.pfc-dyson-arrow-left:before {
    content: '\e924';
    text-transform: none !important;
}
.pfc-dyson-warning:before {
    content: '\e925';
    text-transform: none !important;
}
.pfc-reset:before {
    content: '\e926';
    text-transform: none !important;
}
.pf-zoom-in:before {
    content: '\e927';
    text-transform: none !important;
}
.pfc-checkmark-line:before {
    content: '\e928';
    text-transform: none !important;
}
.pfc-dyson-input-clear:before {
    content: '\e929';
    text-transform: none !important;
}
.pfc-dyson-check:before {
    content: '\e92a';
    text-transform: none !important;
}
body {
    font-size: 16px;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}
.cms-home {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    color: #000000;
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-weight: 500;
    line-height: 1.2;
}
h1,
.h1 {
    font-size: 39.008px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h2,
.h2 {
    font-size: 34px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h3,
.h3 {
    font-size: 28.992px;
    font-family: 'Gotham-Black', Verdana, Arial, sans-serif;
}
h4,
.h4 {
    font-size: 24.992px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
h5,
.h5 {
    font-size: 22px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
h6,
.h6 {
    font-size: 19.008px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
}
p {
    font-size: 16px;
    color: #000000;
    line-height: 1.5;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
p.medium {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
}
p.small {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 12px;
    line-height: 1.5;
}
ul {
    list-style-type: disc;
    list-style-position: outside;
    padding-left: 30px;
}
ul li {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    margin-bottom: 15px;
    /*    &::before {
      content: "•";
      display: inline-block;
      margin-left: 13px;
      margin-right: 13px;
      }*/
}
ul li:last-child {
    margin-bottom: 0;
}
ul.small li {
    font-size: 12px;
}
ul.medium li {
    font-size: 14px;
}
ul.unstyled {
    list-style-type: none;
}
ol {
    list-style-type: decimal-leading-zero;
    counter-reset: li;
    list-style-position: outside;
    padding-left: 30px;
}
ol li {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    position: relative;
    margin-bottom: 15px;
    /*  &:before {
      counter-increment: li;
      content          : counter(li, decimal-leading-zero);

      margin-right : 0.25em;
      font-family  : @font-aleo_regular;
      padding-right: 10px;
    }*/
}
ol li:last-child {
    margin-bottom: 0;
}
ol.small li {
    font-size: 12px;
}
ol.medium li {
    font-size: 14px;
}
ol.unstyled {
    counter-reset: unset;
    list-style-position: outside;
    list-style: none;
    /*  li
    {
      &:before
      {
        display: none ;
      }
    }*/
}
.text-regular_default {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-regular_medium {
    font-size: 14px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-regular_small {
    font-size: 12px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_default {
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_medium {
    font-size: 14px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-semi-bold_small {
    font-size: 12px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_default {
    font-size: 16px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_medium {
    font-size: 14px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
.text-bold_small {
    font-size: 12px;
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    line-height: 1;
}
q,
blockquote,
.quote {
    font-family: 'Aleo-Bold', Verdana, Arial, sans-serif;
    font-size: 34px;
    color: #778186;
    line-height: 1.174;
}
a {
    font-size: 18px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #00457c;
    text-decoration: none;
    cursor: pointer;
}
.bluefoot-wrapper a:not(.btn) {
    text-decoration: underline;
}
a:hover {
    color: #00457c;
}
.new-tag {
    background: #00457c;
    font-size: 16px;
    color: #ffffff;
    padding: 5px 15px;
    text-transform: uppercase;
}
.sale-tag {
    background: #ec5f6c;
    font-size: 16px;
    color: #ffffff;
    padding: 5px 15px;
    text-transform: uppercase;
}
ul.list-group {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}
ul.list-group li {
    font-size: 18px;
    color: #000000;
}
ul.list-group li:before {
    display: inline-block;
    content: '';
    width: 9px;
    height: 9px;
    background: #00457c;
    margin-right: 15px;
}
ol.list-group {
    margin-left: 0;
    padding-left: 0;
    list-style-position: outside;
}
ol.list-group li {
    font-size: 18px;
    color: #000000;
}
.page-title {
    margin-top: 0;
}
.pf-dropdown-option a {
    font-size: 16px;
}
.footer-links li {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.text-line-height {
    line-height: 1.3;
}
h1.spaced,
h2.spaced,
h3.spaced,
h4.spaced,
h5.spaced,
h6.spaced,
.h1.spaced,
.h2.spaced,
.h3.spaced,
.h4.spaced,
.h5.spaced,
.h6.spaced,
p.spaced,
ul.spaced,
ol.spaced,
q.spaced,
blockquote.spaced,
.quote.spaced {
    margin-bottom: 20px;
}
p.spaced + p {
    margin-bottom: 20px;
}
p.spaced + h1,
p.spaced + h2,
p.spaced + h3,
p.spaced + h4,
p.spaced + h5,
p.spaced + h6 {
    margin-top: 40px;
}
p.spaced + ul,
p.spaced + ol,
p.spaced q,
p.spaced blockquote,
p.spaced .quote {
    margin-top: 30px;
}
ul.spaced + p {
    margin-top: 30px;
}
ol.spaced + p {
    margin-top: 30px;
}
q.spaced + p,
blockquote.spaced + p,
.quote.spaced + p {
    margin-top: 30px;
}
.action {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    font-weight: 350;
}
.action:hover,
.action:focus,
.action:active {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.action,
.btn-default {
    line-height: 1;
    height: auto;
    width: auto;
    border-radius: 150px;
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    min-width: 105px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    );
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    border: none;
    padding: 14px 25px;
    font-weight: normal;
    cursor: pointer;
    /*&:hover {
          opacity        : 0.75;
          text-decoration: none;
        }*/
}
.action.primary,
.btn-default.primary,
.action.secondary,
.btn-default.secondary,
.action.tertiary,
.btn-default.tertiary {
    line-height: 1;
    height: auto;
    width: auto;
    border-radius: 150px;
    font-size: 16px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    min-width: 105px;
    padding: 14px 25px;
    font-weight: normal;
}
.action:disabled,
.btn-default:disabled {
    opacity: 0.5;
    cursor: default;
}
.action:disabled:hover,
.btn-default:disabled:hover {
    opacity: 0.5;
    cursor: default;
}
.action.btn-icon,
.btn-default.btn-icon {
    padding: 14px 25px 14px 20px;
}
.action.btn-icon i,
.btn-default.btn-icon i {
    margin-right: 8px;
}
.action.btn-medium,
.btn-default.btn-medium {
    min-width: 92px;
    height: auto;
    padding: 10px 22px;
    font-size: 14px;
}
.action.btn-medium.btn-icon,
.btn-default.btn-medium.btn-icon {
    padding: 10px 22px 10px 17px;
}
.action.btn-medium.btn-icon i,
.btn-default.btn-medium.btn-icon i {
    margin-right: 8px;
    float: left;
}
.action.btn-small,
.btn-default.btn-small {
    height: auto;
    min-width: 80px;
    padding: 6px 19px;
    font-size: 12px;
}
.action.btn-small.btn-icon,
.btn-default.btn-small.btn-icon {
    padding: 6px 19px 6px 14px;
}
.action.btn-small.btn-icon i,
.btn-default.btn-small.btn-icon i {
    margin-right: 8px;
    float: left;
}
.action-icon i,
.btn-default-icon i {
    margin-right: 12px;
    float: left;
}
.action.primary,
.btn-default.primary {
    background: #1e3968;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    );
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    color: #ffffff;
    position: relative;
    overflow: hidden;
}
.action.primary:hover,
.btn-default.primary:hover {
    background-size: 100% 100%;
    border-color: #4b6186;
}
.action.primary:active,
.btn-default.primary:active,
.action.primary:focus,
.btn-default.primary:focus {
    background: #1e3968;
}
.action.primary:disabled,
.btn-default.primary:disabled {
    background: #1e3968;
}
.action.secondary,
.btn-default.secondary,
.action .call-to-action,
.btn-default .call-to-action {
    background: #f59d08;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    );
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    background-repeat: no-repeat;
    color: #ffffff;
}
.action.secondary:hover,
.btn-default.secondary:hover,
.action .call-to-action:hover,
.btn-default .call-to-action:hover {
    background-size: 100% 100%;
    border-color: #f7b139;
}
.action.secondary:active,
.btn-default.secondary:active,
.action .call-to-action:active,
.btn-default .call-to-action:active,
.action.secondary:focus,
.btn-default.secondary:focus,
.action .call-to-action:focus,
.btn-default .call-to-action:focus {
    background: #f59d08;
}
.action.secondary:disabled,
.btn-default.secondary:disabled,
.action .call-to-action:disabled,
.btn-default .call-to-action:disabled {
    background: #f59d08;
    border: 1px #f59d08 solid;
}
.action.full,
.btn-default.full {
    width: 100%;
}
.action.width,
.btn-default.width {
    min-width: 105px !important;
}
.action.link,
.btn-default.link {
    background-color: transparent;
    width: auto;
    margin: 0;
    border: 1px solid transparent;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #1e3968;
}
.action.link:before,
.btn-default.link:before {
    color: #1e3968 !important;
}
.action.tertiary,
.btn-default.tertiary {
    background: transparent;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f1f4f5), to(#f1f4f5));
    background-image: linear-gradient(#f1f4f5, #f1f4f5);
    background-size: 0 100%;
    background-repeat: no-repeat;
    border: 1px transparent solid;
    color: #1e3968;
}
.action.tertiary:hover,
.btn-default.tertiary:hover {
    background-size: 100% 100%;
    border-color: #f1f4f5;
}
.action.tertiary:active,
.btn-default.tertiary:active,
.action.tertiary:focus,
.btn-default.tertiary:focus {
    background: #f1f4f5;
    border: 1px #f1f4f5 solid;
}
.action.tertiary:disabled,
.btn-default.tertiary:disabled {
    background: transparent;
    border: 1px transparent solid;
}
.action.tertiary:disabled:hover,
.btn-default.tertiary:disabled:hover {
    background: transparent;
    border: 1px transparent solid;
}
.action.ghost,
.btn-default.ghost {
    background: #ffffff;
    border: 1px #1e3968 solid;
    color: #1e3968;
    padding: 13px 25px;
}
.action.ghost:hover,
.btn-default.ghost:hover,
.action.ghost:active,
.btn-default.ghost:active,
.action.ghost:focus,
.btn-default.ghost:focus {
    background: #ffffff;
    border: 1px #1e3968 solid;
}
.action.ghost:disabled,
.btn-default.ghost:disabled {
    background: #ffffff;
    border: 1px #1e3968 solid;
}
.action.ghost.btn-icon,
.btn-default.ghost.btn-icon {
    padding: 13px 25px 13px 20px;
}
.action.ghost.btn-medium,
.btn-default.ghost.btn-medium {
    padding: 9px 22px;
}
.action.ghost.btn-medium.btn-icon,
.btn-default.ghost.btn-medium.btn-icon {
    padding: 9px 22px 9px 17px;
}
.action.ghost.btn-small,
.btn-default.ghost.btn-small {
    padding: 5px 19px;
}
.action.ghost.btn-small.btn-icon,
.btn-default.ghost.btn-small.btn-icon {
    padding: 5px 19px 5px 14px;
}
.action:hover,
.btn-default:hover {
    background-size: 100% 100%;
    border-color: #4b6186;
}
.action[class*='pfc-'],
.action[class*='vision-'] {
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    ) !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)) !important;
    background-size: 0 100% !important;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat !important;
    color: #ffffff;
}
.action[class*='pfc-']:hover,
.action[class*='vision-']:hover {
    background-size: 100% 100% !important;
}
.action[class*='pfc-']:disabled,
.action[class*='vision-']:disabled {
    opacity: 0.5;
    cursor: default;
}
.action[class*='pfc-']:disabled:hover,
.action[class*='vision-']:disabled:hover {
    opacity: 0.5;
    cursor: default;
    background-size: 0 0 !important;
}
.action.pfc-dark-blue {
    background: #1e3968;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    );
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
}
.action.pfc-dark-blue_100 {
    background: #ccdae5;
}
.action.pfc-dark-blue_200 {
    background: #a5b0c3;
}
.action.pfc-dark-blue_300 {
    background: #7888a4;
}
.action.pfc-dark-blue_400 {
    background: #4b6186;
}
.action.pfc-dark-blue_500 {
    background: #1e3968;
}
.action.pfc-dark-blue_600 {
    background: #182e53;
}
.action.pfc-dark-blue_700 {
    background: #12223e;
}
.action.pfc-dark-blue_800 {
    background: #0c172a;
}
.action.pfc-dark-blue_900 {
    background: #060b15;
}
.action.pfc-dark-blue:hover {
    background-size: 100% 100%;
}
.action.pfc-dark-red {
    background: #d32f2f;
}
.action.pfc-bright-blue {
    background: #3082f9;
}
.action.pfc-bright-blue_100 {
    background: #eaf3fe;
}
.action.pfc-bright-blue_200 {
    background: #c1dafd;
}
.action.pfc-bright-blue_300 {
    background: #98c1fc;
}
.action.pfc-bright-blue_400 {
    background: #6ea8fb;
}
.action.pfc-bright-blue_500 {
    background: #3082f9;
}
.action.pfc-bright-blue_600 {
    background: #2668c7;
}
.action.pfc-bright-blue_700 {
    background: #1d4e95;
}
.action.pfc-bright-blue_800 {
    background: #133464;
}
.action.pfc-bright-blue_900 {
    background: #0e274b;
}
.action.pfc-bright-green {
    background: #2bd313;
}
.action.pfc-aqua {
    background: #15989d;
}
.action.pfc-green {
    background: #759764;
}
.action.pfc-fresh-green {
    background: #4a9d7d;
}
.action.pfc-light-green {
    background: #aad0c0;
}
.action.pfc-red {
    background: #ec5f6c;
}
.action.pfc-grey_100 {
    background: #f6f6f6;
}
.action.pfc-grey_200 {
    background: #eeeeee;
}
.action.pfc-grey_300 {
    background: #e2e2e2;
}
.action.pfc-grey_400 {
    background: #cccccc;
}
.action.pfc-grey_500 {
    background: #999999;
}
.action.pfc-grey_600 {
    background: #666666;
}
.action.pfc-grey_700 {
    background: #333333;
}
.action.pfc-grey_800 {
    background: #1a1a1a;
}
.action.pfc-yellow-grey_100 {
    background: #f6f3f0;
}
.action.pfc-yellow-grey_200 {
    background: #f1ede9;
}
.action.pfc-yellow-grey_300 {
    background: #e0d7cd;
}
.action.pfc-yellow-grey_400 {
    background: #b3aca4;
}
.action.pfc-blue-grey_100 {
    background: #f1f4f5;
}
.action.pfc-blue-grey_200 {
    background: #e5eaec;
}
.action.pfc-blue-grey_300 {
    background: #9da9b1;
}
.action.pfc-blue-grey_400 {
    background: #778186;
}
.action.pfc-black {
    background: #000000;
}
.action.pfc-white {
    background: #ffffff;
    color: #000000;
}
.action.pfc-orange {
    background: #f59d08;
}
.action.pfc-yellow {
    background: #ffd04d;
}
.action.pfc-light-yellow {
    background: #fdfbcb;
}
.action.vision-on-people {
    background: #367699;
}
.action.vision-on-product {
    background: #79bfca;
}
.action.vision-on-process {
    background: #56a591;
}
.action.green-points {
    background: #8eb53f;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    ) !important;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)) !important;
    background-size: 0 100% !important;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat !important;
    color: #ffffff;
}
.action.green-points:hover {
    background-size: 100% 100% !important;
}
.action.green-points:disabled {
    opacity: 0.5;
    cursor: default;
}
.action.green-points:disabled:hover {
    opacity: 0.5;
    cursor: default;
}
.action.pastel-green {
    background: #cadebc;
    background-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(255, 255, 255, 0.2)),
        to(rgba(255, 255, 255, 0.2))
    );
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
    background-size: 0 100%;
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    color: #ffffff;
}
.action.pastel-green:hover {
    background-size: 100% 100%;
}
.action.pastel-green:disabled {
    opacity: 0.5;
    cursor: default;
}
.action.pastel-green:disabled:hover {
    opacity: 0.5;
    cursor: default;
    background-size: 0 0 !important;
}
.checkbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}
.checkbox input[type='checkbox'] {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    z-index: -1;
}
.checkbox input[type='checkbox']:checked + label::after {
    content: '';
    border-color: #1e3968;
}
.checkbox input[type='checkbox']:checked + label::before {
    border-color: #1e3968;
}
.checkbox input[type='checkbox']:disabled + label {
    opacity: 0.4;
    cursor: not-allowed;
}
.checkbox label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #000000;
    font-size: 16px;
}
.checkbox label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #cccccc;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.checkbox label::after {
    position: absolute;
    left: 0;
    top: 0;
    content: none;
    height: 5px;
    width: 10px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    left: 4px;
    top: 4px;
    border-color: #1e3968;
}
.checkbox label span {
    font-size: 12px;
    color: #d32f2f;
    display: block;
    line-height: 1;
    padding-top: 5px;
}
.checkbox.error input[type='checkbox']:checked + label::before {
    border-color: #d32f2f;
}
.checkbox.error label::before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: 1px solid #d32f2f;
}
.checkbox.label_right label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
}
.checkbox.label_right label::before {
    position: absolute;
    left: 0;
    top: 0;
}
.checkbox.label_right label::after {
    position: absolute;
    left: 0;
    top: 0;
}
.checkbox.label_left label {
    padding-left: 0;
    padding-right: 30px;
}
.checkbox.label_left label::before {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.checkbox.label_left label::after {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    right: 5px;
    top: 5px;
}
.checkbox.label_left.error {
    text-align: right;
}
.checkbox.label_left.error label span {
    text-align: right;
}
.radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
}
.radio input[type='radio'] {
    position: absolute;
    width: 0;
    height: 0;
    border-radius: 50%;
    opacity: 0 !important;
    z-index: -1;
}
.radio input[type='radio']:checked + label::after {
    content: '';
    border-color: #1e3968;
}
.radio input[type='radio']:checked + label::before {
    border-color: #1e3968;
    color: #1e3968;
    padding: 0;
    margin: 0;
    vertical-align: inherit;
    margin-right: 10px;
    background-color: #1e3968;
    border: 5px #fff solid;
    border-color: white !important;
    -webkit-box-shadow: 0 0 0 1px #1e3968;
    box-shadow: 0 0 0 1px #1e3968;
    outline: none;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    /*height: 10px;
          width: 10px;*/
    content: '';
}
.radio input[type='radio']:disabled + label {
    opacity: 0.4;
    cursor: not-allowed;
}
.radio label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    color: #000000;
    font-size: 16px;
    /*  display: flex;
    align-items: center;*/
}
.radio label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*   border: 1px solid @pfc-grey_400;
*/
    -webkit-box-shadow: 0 0 0 1px #cccccc;
    box-shadow: 0 0 0 1px #cccccc;
    outline: none;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    border: none;
}
.radio label span {
    font-size: 12px;
    color: #d32f2f;
    display: block;
    padding-top: 5px;
    line-height: 1;
}
.radio.error input[type='radio']:checked + label::before {
    border-color: #d32f2f;
    -webkit-box-shadow: 0 0 0 1px #d32f2f;
    box-shadow: 0 0 0 1px #d32f2f;
    margin-right: 0;
}
.radio.error label::before {
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px #d32f2f;
    box-shadow: 0 0 0 1px #d32f2f;
}
.radio.label_right label {
    position: relative;
    display: inline-block;
    padding-left: 30px;
    cursor: pointer;
    line-height: 1.25;
}
.radio.label_right label::before {
    position: absolute;
    left: 0;
    top: 0;
}
.radio.label_right label::after {
    position: absolute;
    left: 0;
    top: 0;
}
.radio.label_left label {
    padding-left: 0;
    padding-right: 30px;
}
.radio.label_left label::before {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
}
.radio.label_left label::after {
    position: absolute;
    right: 0;
    left: auto;
    top: 0;
    right: 5px;
    top: 5px;
}
.radio.label_left.error {
    text-align: right;
}
.radio.label_left.error label span {
    text-align: right;
    padding-top: 5px;
}
.radio.label_left input[type='radio']:checked + label::before {
    margin-right: 0;
}
.pfc-dark-blue {
    color: #1e3968;
}
.pfc-dark-blue-bg {
    background-color: #1e3968;
}
.pfc-dark-blue_100 {
    color: #ccdae5;
}
.pfc-dark-blue_100-bg {
    background-color: #ccdae5;
}
.pfc-dark-blue_200 {
    color: #a5b0c3;
}
.pfc-dark-blue_200-bg {
    background-color: #a5b0c3;
}
.pfc-dark-blue_300 {
    color: #7888a4;
}
.pfc-dark-blue_300-bg {
    background-color: #7888a4;
}
.pfc-dark-blue_400 {
    color: #4b6186;
}
.pfc-dark-blue_400-bg {
    background-color: #4b6186;
}
.pfc-dark-blue_500 {
    color: #1e3968;
}
.pfc-dark-blue_500-bg {
    background-color: #1e3968;
}
.pfc-dark-blue_600 {
    color: #182e53;
}
.pfc-dark-blue_600-bg {
    background-color: #182e53;
}
.pfc-dark-blue_700 {
    color: #12223e;
}
.pfc-dark-blue_700-bg {
    background-color: #12223e;
}
.pfc-dark-blue_800 {
    color: #0c172a;
}
.pfc-dark-blue_800-bg {
    background-color: #0c172a;
}
.pfc-dark-blue_900 {
    color: #060b15;
}
.pfc-dark-blue_900-bg {
    background-color: #060b15;
}
.pfc-dark-red {
    color: #d32f2f;
}
.pfc-dark-red-bg {
    background-color: #d32f2f;
}
.pfc-bright-blue {
    color: #3082f9;
}
.pfc-bright-blue-bg {
    background-color: #3082f9;
}
.pfc-bright-blue_100 {
    color: #eaf3fe;
}
.pfc-bright-blue_100-bg {
    background-color: #eaf3fe;
}
.pfc-bright-blue_200 {
    color: #c1dafd;
}
.pfc-bright-blue_200-bg {
    background-color: #c1dafd;
}
.pfc-bright-blue_300 {
    color: #98c1fc;
}
.pfc-bright-blue_300-bg {
    background-color: #98c1fc;
}
.pfc-bright-blue_400 {
    color: #6ea8fb;
}
.pfc-bright-blue_400-bg {
    background-color: #6ea8fb;
}
.pfc-bright-blue_500 {
    color: #3082f9;
}
.pfc-bright-blue_500-bg {
    background-color: #3082f9;
}
.pfc-bright-blue_600 {
    color: #2668c7;
}
.pfc-bright-blue_600-bg {
    background-color: #2668c7;
}
.pfc-bright-blue_700 {
    color: #1d4e95;
}
.pfc-bright-blue_700-bg {
    background-color: #1d4e95;
}
.pfc-bright-blue_800 {
    color: #133464;
}
.pfc-bright-blue_800-bg {
    background-color: #133464;
}
.pfc-bright-blue_900 {
    color: #0e274b;
}
.pfc-bright-blue_900-bg {
    background-color: #0e274b;
}
.pfc-bright-green {
    color: #2bd313;
}
.pfc-bright-green-bg {
    background-color: #2bd313;
}
.pfc-aqua {
    color: #15989d;
}
.pfc-aqua-bg {
    background-color: #15989d;
}
.pfc-green {
    color: #759764;
}
.pfc-green-bg {
    background-color: #759764;
}
.pfc-fresh-green {
    color: #4a9d7d;
}
.pfc-fresh-green-bg {
    background-color: #4a9d7d;
}
.pfc-light-green {
    color: #aad0c0;
}
.pfc-light-green-bg {
    background-color: #aad0c0;
}
.pfc-red {
    color: #ec5f6c;
}
.pfc-red-bg {
    background-color: #ec5f6c;
}
.pfc-grey_100 {
    color: #f6f6f6;
}
.pfc-grey_100-bg {
    background-color: #f6f6f6;
}
.pfc-grey_200 {
    color: #eeeeee;
}
.pfc-grey_200-bg {
    background-color: #eeeeee;
}
.pfc-grey_300 {
    color: #e2e2e2;
}
.pfc-grey_300-bg {
    background-color: #e2e2e2;
}
.pfc-grey_400 {
    color: #cccccc;
}
.pfc-grey_400-bg {
    background-color: #cccccc;
}
.pfc-grey_500 {
    color: #999999;
}
.pfc-grey_500-bg {
    background-color: #999999;
}
.pfc-grey_600 {
    color: #666666;
}
.pfc-grey_600-bg {
    background-color: #666666;
}
.pfc-grey_700 {
    color: #333333;
}
.pfc-grey_700-bg {
    background-color: #333333;
}
.pfc-grey_800 {
    color: #1a1a1a;
}
.pfc-grey_800-bg {
    background-color: #1a1a1a;
}
.pfc-yellow-grey_100 {
    color: #f6f3f0;
}
.pfc-yellow-grey_100-bg {
    background-color: #f6f3f0;
}
.pfc-yellow-grey_200 {
    color: #f1ede9;
}
.pfc-yellow-grey_200-bg {
    background-color: #f1ede9;
}
.pfc-yellow-grey_300 {
    color: #e0d7cd;
}
.pfc-yellow-grey_300-bg {
    background-color: #e0d7cd;
}
.pfc-yellow-grey_400 {
    color: #b3aca4;
}
.pfc-yellow-grey_400-bg {
    background-color: #b3aca4;
}
.pfc-blue-grey_100 {
    color: #f1f4f5;
}
.pfc-blue-grey_100-bg {
    background-color: #f1f4f5;
}
.pfc-blue-grey_200 {
    color: #e5eaec;
}
.pfc-blue-grey_200-bg {
    background-color: #e5eaec;
}
.pfc-blue-grey_300 {
    color: #9da9b1;
}
.pfc-blue-grey_300-bg {
    background-color: #9da9b1;
}
.pfc-blue-grey_400 {
    color: #778186;
}
.pfc-blue-grey_400-bg {
    background-color: #778186;
}
.pfc-black {
    color: #000000;
}
.pfc-black-bg {
    background-color: #000000;
}
.pfc-white {
    color: #ffffff;
}
.pfc-white-bg {
    background-color: #ffffff;
}
.pfc-orange {
    color: #f59d08;
}
.pfc-orange-bg {
    background-color: #f59d08;
}
.pfc-yellow {
    color: #ffd04d;
}
.pfc-yellow-bg {
    background-color: #ffd04d;
}
.pfc-light-yellow {
    color: #fdfbcb;
}
.pfc-light-yellow-bg {
    background-color: #fdfbcb;
}
.vision-on-people {
    color: #367699;
}
.vision-on-people-bg {
    background-color: #367699;
}
.vision-on-product {
    color: #79bfca;
}
.vision-on-product-bg {
    background-color: #79bfca;
}
.vision-on-process {
    color: #56a591;
}
.vision-on-process-bg {
    background-color: #56a591;
}
.green-points {
    color: #8eb53f;
}
.green-points-bg {
    background-color: #8eb53f;
}
.pastel-green {
    color: #cadebc;
}
.pastel-green-bg {
    background-color: #cadebc;
}
.pfc-spacer_5 {
    height: 5px;
}
.pfc-spacer_10 {
    height: 10px;
}
.pfc-spacer_15 {
    height: 15px;
}
.pfc-spacer_20 {
    height: 20px;
}
.pfc-spacer_25 {
    height: 25px;
}
.pfc-spacer_30 {
    height: 30px;
}
.pfc-spacer_35 {
    height: 35px;
}
.pfc-spacer_40 {
    height: 40px;
}
.pfc-spacer_60 {
    height: 60px;
}
.pfc-spacer_80 {
    height: 80px;
}
.m-t_5 {
    margin-top: 5px;
}
.m-t_10 {
    margin-top: 10px;
}
.m-t_15 {
    margin-top: 15px;
}
.m-t_20 {
    margin-top: 20px;
}
.m-t_25 {
    margin-top: 25px;
}
.m-t_30 {
    margin-top: 30px;
}
.m-t_35 {
    margin-top: 35px;
}
.m-t_40 {
    margin-top: 40px;
}
.m-t_60 {
    margin-top: 60px;
}
.m-t_80 {
    margin-top: 80px;
}
.m-b_5 {
    margin-bottom: 5px;
}
.m-b_10 {
    margin-bottom: 10px;
}
.m-b_15 {
    margin-bottom: 15px;
}
.m-b_20 {
    margin-bottom: 20px;
}
.m-b_25 {
    margin-bottom: 25px;
}
.m-b_30 {
    margin-bottom: 30px;
}
.m-b_35 {
    margin-bottom: 35px;
}
.m-b_40 {
    margin-bottom: 40px;
}
.m-b_60 {
    margin-bottom: 60px;
}
.m-b_80 {
    margin-bottom: 80px;
}
.m-l_5 {
    margin-left: 5px;
}
.m-l_10 {
    margin-left: 10px;
}
.m-l_15 {
    margin-left: 15px;
}
.m-l_20 {
    margin-left: 20px;
}
.m-l_25 {
    margin-left: 25px;
}
.m-l_30 {
    margin-left: 30px;
}
.m-l_35 {
    margin-left: 35px;
}
.m-l_40 {
    margin-left: 40px;
}
.m-l_60 {
    margin-left: 60px;
}
.m-l_80 {
    margin-left: 80px;
}
.m-r_5 {
    margin-right: 5px;
}
.m-r_10 {
    margin-right: 10px;
}
.m-r_15 {
    margin-right: 15px;
}
.m-r_20 {
    margin-right: 20px;
}
.m-r_25 {
    margin-right: 25px;
}
.m-r_30 {
    margin-right: 30px;
}
.m-r_35 {
    margin-right: 35px;
}
.m-r_40 {
    margin-right: 40px;
}
.m-r_60 {
    margin-right: 60px;
}
.m-r_80 {
    margin-right: 80px;
}
.p-t_5 {
    padding-top: 5px;
}
.p-t_10 {
    padding-top: 10px;
}
.p-t_15 {
    padding-top: 15px;
}
.p-t_20 {
    padding-top: 20px;
}
.p-t_25 {
    padding-top: 25px;
}
.p-t_30 {
    padding-top: 30px;
}
.p-t_35 {
    padding-top: 35px;
}
.p-t_40 {
    padding-top: 40px;
}
.p-t_60 {
    padding-top: 60px;
}
.p-t_80 {
    padding-top: 80px;
}
.p-b_5 {
    padding-bottom: 5px;
}
.p-b_10 {
    padding-bottom: 10px;
}
.p-b_15 {
    padding-bottom: 15px;
}
.p-b_20 {
    padding-bottom: 20px;
}
.p-b_25 {
    padding-bottom: 25px;
}
.p-b_30 {
    padding-bottom: 30px;
}
.p-b_35 {
    padding-bottom: 35px;
}
.p-b_40 {
    padding-bottom: 40px;
}
.p-b_60 {
    padding-bottom: 60px;
}
.p-b_80 {
    padding-bottom: 80px;
}
.p-l_5 {
    padding-left: 5px;
}
.p-l_10 {
    padding-left: 10px;
}
.p-l_15 {
    padding-left: 15px;
}
.p-l_20 {
    padding-left: 20px;
}
.p-l_25 {
    padding-left: 25px;
}
.p-l_30 {
    padding-left: 30px;
}
.p-l_35 {
    padding-left: 35px;
}
.p-l_40 {
    padding-left: 40px;
}
.p-l_60 {
    padding-left: 60px;
}
.p-l_80 {
    padding-left: 80px;
}
.p-r_5 {
    padding-right: 5px;
}
.p-r_10 {
    padding-right: 10px;
}
.p-r_15 {
    padding-right: 15px;
}
.p-r_20 {
    padding-right: 20px;
}
.p-r_25 {
    padding-right: 25px;
}
.p-r_30 {
    padding-right: 30px;
}
.p-r_35 {
    padding-right: 35px;
}
.p-r_40 {
    padding-right: 40px;
}
.p-r_60 {
    padding-right: 60px;
}
.p-r_80 {
    padding-right: 80px;
}
table.pfc-table {
    border-collapse: collapse;
    margin: 0;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #000000;
    width: 100%;
}
table.pfc-table tr {
    border-left: 3px transparent solid;
}
table.pfc-table_has-border {
    border: 1px solid #e2e2e2;
}
table.pfc-table.has-shadow {
    outline: 1px solid #e2e2e2;
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
table.pfc-table thead tr {
    background-color: #ffffff;
    border-bottom: 1px solid #e2e2e2;
    text-align: left;
}
table.pfc-table tbody.parent-tr.active tr {
    border-left: 3px solid #00457c;
}
table.pfc-table tbody.child-tr tr {
    background: #fff;
    border-left: 3px solid #00457c;
}
table.pfc-table tbody.child-tr tr td {
    background: transparent;
}
table.pfc-table tbody.child-tr tr:first-child {
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#f2f2f2),
            color-stop(23.44%, rgba(255, 255, 255, 0))
        ),
        #ffffff;
    background: linear-gradient(180deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tbody.child-tr tr:last-child {
    background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(#f2f2f2),
            color-stop(23.44%, rgba(255, 255, 255, 0))
        ),
        #ffffff;
    background: linear-gradient(0deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tbody:last-child tr:last-child td {
    border-bottom: none !important;
}
table.pfc-table th {
    font-family: 'Gotham-Bold', Verdana, Arial, sans-serif;
    white-space: nowrap;
    font-weight: normal;
}
table.pfc-table th a {
    color: #1e3968;
}
table.pfc-table th a:hover {
    text-decoration: none;
}
table.pfc-table th i {
    font-size: 14px;
    color: #1e3968;
    font-family: 'pfc-font-icon';
    vertical-align: middle;
    margin-left: 10px;
}
table.pfc-table tr th,
table.pfc-table tr td {
    padding: 10px;
    margin: 10px;
    height: 61px;
    background: #ffffff;
    border-bottom: 1px solid #e2e2e2 !important;
    line-height: 1;
    vertical-align: middle;
}
table.pfc-table tr th:last-child a,
table.pfc-table tr td:last-child a {
    color: #000000;
    margin-left: 10px;
}
table.pfc-table tr th:last-child a:first-child,
table.pfc-table tr td:last-child a:first-child {
    margin-left: 0;
}
table.pfc-table tr th.align-left,
table.pfc-table tr td.align-left {
    text-align: left;
}
table.pfc-table tr th.align-right,
table.pfc-table tr td.align-right {
    text-align: right;
}
table.pfc-table tr td.has-icon i {
    font-size: 16px;
    color: #1e3968;
    font-family: 'pfc-font-icon';
    display: inline-block;
    vertical-align: top;
    -webkit-transition: -webkit-transform 0.2s ease 0s;
    transition: -webkit-transform 0.2s ease 0s;
    transition: transform 0.2s ease 0s;
    transition:
        transform 0.2s ease 0s,
        -webkit-transform 0.2s ease 0s;
}
table.pfc-table tr td.has-icon i.active {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
table.pfc-table tr td img {
    display: block;
    max-height: 40px;
    width: auto;
}
table.pfc-table tr tbody tr {
    border-bottom: 1px solid #e2e2e2;
}
table.pfc-table tr tbody tr.hidden-tr + .hidden-tr {
    background: #fff;
}
table.pfc-table tr tbody tr.hidden-tr td {
    background: transparent;
}
table.pfc-table tr tbody tr.hidden-tr:first-child {
    background: -webkit-gradient(
            linear,
            left top,
            left bottom,
            from(#f2f2f2),
            color-stop(23.44%, rgba(255, 255, 255, 0))
        ),
        #ffffff;
    background: linear-gradient(180deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tr tbody tr.hidden-tr:last-child {
    background: -webkit-gradient(
            linear,
            left bottom,
            left top,
            from(#f2f2f2),
            color-stop(23.44%, rgba(255, 255, 255, 0))
        ),
        #ffffff;
    background: linear-gradient(0deg, #f2f2f2 0%, rgba(255, 255, 255, 0) 23.44%), #ffffff;
}
table.pfc-table tr tbody tr:last-of-type {
    border-bottom: 1px solid #e2e2e2;
}
table.pfc-table tr tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}
table.pfc-table.pfc-table_advance tr td.clipped-content {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}
.pfc-table-container {
    padding: 20px;
    background: #f6f6f6;
    border-radius: 8px;
}
.pfc-table-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.pfc-table-header .action {
    margin-left: 20px;
}
.pfc-table-header .form-control {
    font-size: 16px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
.pfc-table-header .input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
}
.pfc-table-header .input-group.select::after {
    font-family: 'pfc-font-icon';
    font-size: 14px;
    content: '\64';
    color: #1e3968;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    position: absolute;
    right: 12px;
    -webkit-transition: all 0.25s;
    transition: all 0.25s;
    top: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pfc-table-filter {
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-table-filter select.form-control {
    width: 70px;
}
.pfc-table-filter h3 {
    line-height: 1;
}
.pfc-table-filter .input-group {
    margin-right: 20px;
    margin-left: 20px;
}
.pfc-table-filter-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}
.pfc-table-search {
    border-top: 1px solid #cccccc;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-table-search .input-group {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.pfc-table-search .input-group input {
    width: 100%;
}
.pfc-table-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-table-footer.align-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.pfc-table-footer.align-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
td.test {
    width: 100px;
}
.pfc-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: underline !important;
    color: #1e3968;
}
.pfc-link.icon-link {
    text-decoration: none !important;
}
.pfc-link i {
    font-size: inherit;
    color: inherit;
    margin-right: 8px;
}
.pfc-link.block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.pfc-divider {
    width: 100%;
    height: 1px;
    display: block;
    background: #999999;
}
.pfc-divider_default {
    background: #999999;
}
.pfc-divider_light {
    background: #e2e2e2;
}
.pfc-divider_black {
    background: #000000;
}
.pfc-divider_vertical {
    width: 1px;
    height: 100%;
    min-height: 1px;
}
.pfc-shadow_ambient-10 {
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-15 {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-20 {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_ambient-25 {
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-10 {
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-15 {
    -webkit-box-shadow: 0px 15px 20px -5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 15px 20px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-20 {
    -webkit-box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_shifted-25 {
    -webkit-box-shadow: 0px 25px 30px -5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-10 {
    -webkit-box-shadow:
        0px 0px 10px 0px rgba(0, 0, 0, 0.12),
        0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    box-shadow:
        0px 0px 10px 0px rgba(0, 0, 0, 0.12),
        0px 10px 15px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-15 {
    -webkit-box-shadow:
        0px 0px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 20px -5px rgba(0, 0, 0, 0.12);
    box-shadow:
        0px 0px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 20px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-20 {
    -webkit-box-shadow:
        0px 0px 20px 0px rgba(0, 0, 0, 0.12),
        0px 20px 25px -5px rgba(0, 0, 0, 0.12);
    box-shadow:
        0px 0px 20px 0px rgba(0, 0, 0, 0.12),
        0px 20px 25px -5px rgba(0, 0, 0, 0.12);
}
.pfc-shadow_elevation-25 {
    -webkit-box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
    box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-range-slider {
    position: relative;
    width: 100%;
}
.pfc-range-slider .slider {
    position: relative;
    z-index: 1;
    height: 6px;
    margin: 0 15px;
}
.pfc-range-slider .slider > .track {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #e2e2e2;
}
.pfc-range-slider .slider > .range {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #2bd313;
}
.pfc-range-slider .slider > .thumb {
    position: absolute;
    z-index: 3;
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 0 rgba(30, 57, 104, 0.1);
    box-shadow: 0 0 0 0 rgba(30, 57, 104, 0.1);
    -webkit-transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: -webkit-box-shadow 0.3s ease-in-out;
    transition: box-shadow 0.3s ease-in-out;
    transition:
        box-shadow 0.3s ease-in-out,
        -webkit-box-shadow 0.3s ease-in-out;
}
.pfc-range-slider .slider > .thumb.left {
    left: 0;
    -webkit-transform: translate(-9px, -6px);
    transform: translate(-9px, -6px);
}
.pfc-range-slider .slider > .thumb.right {
    right: 25%;
    -webkit-transform: translate(9px, -6px);
    transform: translate(9px, -6px);
}
.pfc-range-slider .slider > .thumb.hover {
    -webkit-box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.1);
    box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.1);
}
.pfc-range-slider .slider > .thumb.active {
    -webkit-box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.2);
    box-shadow: 0 0 0 5px rgba(30, 57, 104, 0.2);
}
.pfc-range-slider .multi-range-slider input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
}
.pfc-range-slider .multi-range-slider input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: #1e3968;
    -webkit-appearance: none;
}
.pfc-range-slider .standard-slider input[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 15px;
    height: 6px;
    background: #ccc;
}
.pfc-range-slider .standard-slider input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    border: none;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.pfc-range-slider .standard-slider input[type='range']::-moz-range-thumb {
    width: 18px;
    height: 18px;
    background-color: #1e3968;
    border-radius: 50%;
    border: none;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
.pfc-range-slider .standard-slider .value {
    display: none;
}
.pfc-notification {
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 16px;
    line-height: 1;
    color: #ffffff;
    padding: 5px 10px 5px 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-notification i {
    padding: 6px;
    margin-right: 5px;
}
.pfc-notification_success {
    background-color: #2bd313;
}
.pfc-notification_information {
    background-color: #3082f9;
}
.pfc-notification_warning {
    background-color: #f59d08;
}
.pfc-notification_error {
    background-color: #d32f2f;
}
.pfc-notification_detail {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    background-color: #fdfbcb;
    color: #000000;
    padding: 15px 30px;
    position: relative;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% calc(100% - 26px), calc(100% - 26px) 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 26px), calc(100% - 26px) 100%, 0 100%);
}
.pfc-notification_detail a {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #1e3968;
    margin-left: 5px;
}
.pfc-notification-bar {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 57px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
    line-height: 1.5;
    color: #000000;
    position: relative;
}
.pfc-notification-bar_important {
    background-color: #ffd04d;
}
.pfc-notification-bar_positive {
    background-color: #afe7b4;
}
.pfc-notification-bar_neutral {
    background-color: #e2e2e2;
}
.pfc-notification-bar .detail-btn {
    border: 1px #ffffff solid;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin-left: 30px;
    background: #ffffff;
}
.pfc-notification-bar .close-notification {
    background: none;
    border: none;
    position: absolute;
    right: 57px;
    font-size: 16px;
    padding: 0;
}
.pfc-notification-bar .close-notification i {
    padding: 0;
    margin-right: 0;
}
.pfc-notification-bar-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1920px;
    padding-left: 157px;
    padding-right: 157px;
}
.pfc-toggle {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-toggle label {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-radius: 50px;
    cursor: pointer;
    width: 56px;
    height: 30px;
    display: block;
}
.pfc-toggle input {
    position: absolute;
    display: none;
}
.pfc-toggle input:checked ~ .slider {
    background-color: #2bd313;
    border: 1px #2bd313 solid;
}
.pfc-toggle input:checked ~ .slider::before {
    -webkit-transform: translateX(26px);
    transform: translateX(26px);
    background-color: #ffffff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.pfc-toggle .slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border: 1px #cccccc solid;
}
.pfc-toggle .slider::before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #1e3968;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}
.pfc-toggle span.label {
    color: #000000;
    margin-left: 15px;
}
.pfc-toggle.label-left {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.pfc-toggle.label-left span.label {
    margin-left: 0;
    margin-right: 15px;
}
.pfc-toggle.toggle_medium label {
    height: 25px;
    width: 46px;
}
.pfc-toggle.toggle_medium .slider:before {
    height: 19px;
    width: 19px;
}
.pfc-toggle.toggle_medium input:checked ~ .slider::before {
    -webkit-transform: translateX(21px);
    transform: translateX(21px);
}
.pfc-toggle.toggle_small label {
    height: 20px;
    width: 36px;
}
.pfc-toggle.toggle_small .slider:before {
    height: 14px;
    width: 14px;
}
.pfc-toggle.toggle_small input:checked ~ .slider::before {
    -webkit-transform: translateX(16px);
    transform: translateX(16px);
}
.pfc-toggle.disabled {
    opacity: 0.5;
    cursor: default;
}
.pfc-toggle.disabled label {
    cursor: default;
}
.pfc-segmented-button {
    padding: 1px;
    background: #ffffff;
    border: 1px #e2e2e2 solid;
    height: 44px;
    border-radius: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.pfc-segmented-button .btn {
    border: none;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    -webkit-transition:
        background 600ms ease,
        color 600ms ease;
    transition:
        background 600ms ease,
        color 600ms ease;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    padding: 0 52px;
    font-size: 16px;
    border-radius: 22px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.pfc-segmented-button input[type='radio'].toggle {
    display: none;
}
.pfc-segmented-button input[type='radio'].toggle + label {
    cursor: pointer;
}
.pfc-segmented-button input[type='radio'].toggle + label:hover {
    background: none;
}
.pfc-segmented-button input[type='radio'].toggle + label:after {
    background: #e5eaec;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-transition: left 300ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: left 300ms cubic-bezier(0.77, 0, 0.175, 1);
    width: 100%;
    z-index: -1;
    color: #000000;
    border-radius: 22px;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-left + label {
    border-right: 0;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-left + label:after {
    left: 100%;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-right + label {
    margin-left: -5px;
}
.pfc-segmented-button input[type='radio'].toggle.toggle-right + label:after {
    left: -100%;
}
.pfc-segmented-button input[type='radio'].toggle:checked + label {
    cursor: default;
    color: #000000;
    -webkit-transition: color 200ms;
    transition: color 200ms;
    z-index: 0;
}
.pfc-segmented-button input[type='radio'].toggle:checked + label:after {
    left: 0;
}
.pfc-segmented-button.disabled {
    opacity: 0.5;
    cursor: default;
}
.pfc-segmented-button.disabled label {
    cursor: default;
}
.pfc-tooltip {
    position: relative;
    display: inline-block;
    font-size: 30px;
}
.pfc-tooltip.tooltip-medium {
    font-size: 24px;
}
.pfc-tooltip.tooltip-medium.tooltip_top .tooltip-content {
    bottom: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_top-left .tooltip-content {
    bottom: 145%;
    right: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_top-right .tooltip-content {
    bottom: 145%;
    left: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom-left .tooltip-content {
    top: 145%;
    right: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom-right .tooltip-content {
    top: 145%;
    left: -3px;
}
.pfc-tooltip.tooltip-medium.tooltip_bottom .tooltip-content {
    top: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_right .tooltip-content {
    left: 145%;
}
.pfc-tooltip.tooltip-medium.tooltip_left .tooltip-content {
    right: 145%;
}
.pfc-tooltip.tooltip-small {
    font-size: 18px;
}
.pfc-tooltip.tooltip-small.tooltip_top .tooltip-content {
    bottom: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_top-left .tooltip-content {
    bottom: 155%;
    right: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_top-right .tooltip-content {
    bottom: 155%;
    left: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom-left .tooltip-content {
    top: 155%;
    right: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom-right .tooltip-content {
    top: 155%;
    left: -6px;
}
.pfc-tooltip.tooltip-small.tooltip_bottom .tooltip-content {
    top: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_right .tooltip-content {
    left: 155%;
}
.pfc-tooltip.tooltip-small.tooltip_left .tooltip-content {
    right: 155%;
}
.pfc-tooltip:hover {
    overflow: visible;
    opacity: 1 !important;
}
.pfc-tooltip:hover .tooltip-content {
    opacity: 1;
}
.pfc-tooltip .tooltip-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-tooltip .tooltip-content {
    background: #333333;
    -webkit-box-shadow: 0 5px 5px 5px rgba(205, 210, 214, 0.3);
    box-shadow: 0 5px 5px 5px rgba(205, 210, 214, 0.3);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #ffffff;
    font-size: 14px;
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    line-height: 1;
    max-width: 380px;
    min-width: auto;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 10px;
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 4px;
    line-height: 1.2;
}
.pfc-tooltip .tooltip-content::after {
    background: #333333;
    content: '';
    height: 10px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 10px;
}
.pfc-tooltip.tooltip_top .tooltip-content {
    bottom: 145%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.pfc-tooltip.tooltip_top .tooltip-content::after {
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_top-left .tooltip-content {
    bottom: 145%;
    right: 0;
}
.pfc-tooltip.tooltip_top-left .tooltip-content::after {
    bottom: -5px;
    right: 10px;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_top-right .tooltip-content {
    bottom: 145%;
    left: 0;
}
.pfc-tooltip.tooltip_top-right .tooltip-content::after {
    bottom: -5px;
    left: 10px;
}
.pfc-tooltip.tooltip_bottom-left .tooltip-content {
    top: 145%;
    right: 0;
}
.pfc-tooltip.tooltip_bottom-left .tooltip-content::after {
    top: -5px;
    right: 10px;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_bottom-right .tooltip-content {
    top: 145%;
    left: 0;
}
.pfc-tooltip.tooltip_bottom-right .tooltip-content::after {
    top: -5px;
    left: 10px;
}
.pfc-tooltip.tooltip_bottom .tooltip-content {
    top: 145%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
.pfc-tooltip.tooltip_bottom .tooltip-content::after {
    top: -5px;
    left: 50%;
    margin-left: -5px;
}
.pfc-tooltip.tooltip_right .tooltip-content {
    left: 145%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pfc-tooltip.tooltip_right .tooltip-content::after {
    left: -5px;
    margin-top: -5px;
    top: 50%;
}
.pfc-tooltip.tooltip_left .tooltip-content {
    right: 145%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.pfc-tooltip.tooltip_left .tooltip-content::after {
    right: -5px;
    margin-top: -5px;
    top: 50%;
}
.pfc-form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}
.pfc-form-group .input-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #1e3968;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 18px;
    white-space: nowrap;
}
.pfc-form-group.has-error .input-icon {
    background: #d32f2f;
}
.pfc-form-group.has-error span.error-message {
    display: block;
    margin: 5px 12px;
    width: 100%;
    color: #d32f2f;
    font-size: 12px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-form-group_disabled .input-icon {
    opacity: 0.5;
}
.pfc-form-control {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px #e2e2e2 solid;
    border-radius: 0;
    background: #ffffff;
    background-clip: padding-box;
    padding: 12px;
    width: 1%;
    min-width: 0;
    height: 40px;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-form-control::-ms-input-placeholder {
    color: #cccccc;
}
.pfc-form-control::-webkit-input-placeholder {
    color: #cccccc;
}
.pfc-form-control:-ms-input-placeholder {
    color: #cccccc;
}
.pfc-form-control::placeholder {
    color: #cccccc;
}
.pfc-form-control:active,
.pfc-form-control:focus-visible {
    outline: none;
    border-color: #1e3968;
    border-radius: 0;
    color: #000000;
}
.pfc-form-control:disabled {
    opacity: 50%;
}
.pfc-form-control:disabled:active {
    border: 1px #e2e2e2 solid;
}
.pfc-form-control--error,
.pfc-form-control:invalid {
    border-color: #d32f2f;
    color: #000000;
}
.pfc-form-control--error + span.error-message,
.pfc-form-control:invalid + span.error-message {
    display: block;
    margin: 5px 12px;
    width: 100%;
    color: #d32f2f;
    font-size: 12px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
textarea {
    resize: none;
}
textarea.pfc-form-control {
    padding: 10px 12px;
    min-height: 104px;
}
.pfc-select {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    border: 1px #e2e2e2 solid;
    background: #fff;
    padding: 0;
    height: 40px;
    font-size: 16px;
}
.pfc-select.disabled {
    opacity: 0.5;
}
.pfc-select.disabled .inputContainer input {
    cursor: default;
}
.pfc-select.error {
    border-color: #d32f2f;
}
.pfc-select .inputContainer {
    width: 100%;
}
.pfc-select .inputContainer input {
    position: relative;
    z-index: 1;
    cursor: pointer;
    border: none;
    background: transparent;
    padding: 12px;
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 16px;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-select .inputContainer input:focus-visible {
    outline: none;
    border: none;
}
.pfc-select .inputContainer:after {
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 0;
    cursor: pointer;
    content: '';
    content: '\64';
    color: #1e3968;
    font-size: 14px;
    font-family: 'pfc-font-icon';
}
.pfc-select .inputContainer.select-active:after {
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translateY(-50%) rotate(180deg);
    transform: translateY(-50%) rotate(180deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
    content: '\64';
    font-family: 'pfc-font-icon';
}
.pfc-select ul.dropdown-list_all {
    display: none;
    position: absolute;
    top: 39px;
    right: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    -webkit-box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.12);
    border: 1px #e2e2e2 solid;
    border-top: none;
    background: #ffffff;
    padding: 0;
    width: 100%;
    list-style: none;
}
.pfc-select ul.dropdown-list_all li {
    cursor: pointer;
    margin-bottom: 0;
    padding: 8px 12px;
    color: #000000;
    font-size: 16px;
    line-height: 1;
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-select.pfc-select_color .inputContainer span.color-icon {
    display: inline-block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 24px;
    height: 24px;
}
.pfc-select.pfc-select_color .inputContainer input {
    padding-left: 42px;
}
.pfc-select.pfc-select_color ul.dropdown-list_all {
    gap: 5px;
    padding: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.pfc-select.pfc-select_color ul.dropdown-list_all li {
    display: inline-block;
    padding: 0;
}
.pfc-select.pfc-select_color ul.dropdown-list_all li span.logo-color_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
}
.pfc-select.pfc-select_color ul#dropdown-list_all_pfc-select_color {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.pfc-select.pfc-select_image .inputContainer img {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    width: 38px;
    height: 38px;
}
.pfc-select.pfc-select_image .inputContainer input {
    padding-left: 50px;
}
.pfc-select.pfc-select_image ul {
    padding: 8px;
    gap: 10px;
}
.pfc-select.pfc-select_image ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
}
.pfc-select.pfc-select_image ul li:last-child {
    margin-bottom: 0;
}
.pfc-select.pfc-select_image ul li img {
    display: inline-block;
    margin-right: 10px;
    width: 38px;
    height: 38px;
}
.pfc-dialog {
    max-width: 1920px;
    width: 100%;
    padding: 0 57px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-dialog .modal-inner-wrap {
    position: relative;
    background: #ffffff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 22px;
    -webkit-box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
    box-shadow:
        0px 0px 25px 0px rgba(0, 0, 0, 0.12),
        0px 25px 30px -5px rgba(0, 0, 0, 0.12);
}
.pfc-dialog .modal-inner-wrap [data-role='header'],
.pfc-dialog .modal-inner-wrap header {
    height: auto;
    padding-top: 0;
    position: absolute;
    right: 0;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close,
.pfc-dialog .modal-inner-wrap header .action-close {
    background: 0 0;
    border: 0;
    padding: 0 20px;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close:before,
.pfc-dialog .modal-inner-wrap header .action-close:before {
    font-family: 'pfc-font-icon';
    font-size: 20px;
    content: '\6b';
    color: #000000;
    padding: 0;
    margin: 0;
    vertical-align: unset;
}
.pfc-dialog .modal-inner-wrap [data-role='header'] .action-close span,
.pfc-dialog .modal-inner-wrap header .action-close span {
    display: none;
}
.pfc-dialog .modal-inner-wrap .modal-content-wrap [data-role='content'] {
    padding-left: 10px;
    padding-right: 10px;
}
.pfc-dialog .modal-inner-wrap .modal-content-wrap [data-role='content'] h2 {
    margin-top: 0;
    margin-bottom: 0;
}
.pfc-dialog .modal-inner-wrap .modal-content-inner {
    margin-top: 20px;
}
.pfc-dialog .modal-inner-wrap .modal-content-inner p {
    margin: 0;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer {
    margin-top: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer button.action {
    margin-left: 20px;
    margin-right: 0;
}
.pfc-dialog .modal-inner-wrap footer.modal-footer button.action:first-child {
    margin-left: 0;
}
.pfc-dialog_small .modal-inner-wrap {
    min-width: unset;
    width: 33.33% !important;
}
.pfc-dialog_medium .modal-inner-wrap {
    min-width: unset;
    width: 50% !important;
}
.pfc-dialog_large .modal-inner-wrap {
    min-width: unset;
    width: 66.66% !important;
}
.pfc-dialog_max .modal-inner-wrap {
    min-width: unset;
    width: 83.33% !important;
}
.pfc-dialog_top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 40px;
}
.pfc-dialog_middle {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-dialog_bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    padding-bottom: 40px;
}
.container {
    max-width: 1920px;
    padding-left: 57px;
    padding-right: 57px;
}
.flex-row {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    -ms-flex-direction: row;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}
.flex-col {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    padding: 1rem;
}
.flex-col-xs-1,
.flex-col-sm-1,
.flex-col-md-1,
.flex-col-lg-1,
.flex-col-xl-1,
.flex-col-xs-2,
.flex-col-sm-2,
.flex-col-md-2,
.flex-col-lg-2,
.flex-col-xl-2,
.flex-col-xs-3,
.flex-col-sm-3,
.flex-col-md-3,
.flex-col-lg-3,
.flex-col-xl-3,
.flex-col-xs-4,
.flex-col-sm-4,
.flex-col-md-4,
.flex-col-lg-4,
.flex-col-xl-4,
.flex-col-xs-5,
.flex-col-sm-5,
.flex-col-md-5,
.flex-col-lg-5,
.flex-col-xl-5,
.flex-col-xs-6,
.flex-col-sm-6,
.flex-col-md-6,
.flex-col-lg-6,
.flex-col-xl-6,
.flex-col-xs-7,
.flex-col-sm-7,
.flex-col-md-7,
.flex-col-lg-7,
.flex-col-xl-7,
.flex-col-xs-8,
.flex-col-sm-8,
.flex-col-md-8,
.flex-col-lg-8,
.flex-col-xl-8,
.flex-col-xs-9,
.flex-col-sm-9,
.flex-col-md-9,
.flex-col-lg-9,
.flex-col-xl-9,
.flex-col-xs-10,
.flex-col-sm-10,
.flex-col-md-10,
.flex-col-lg-10,
.flex-col-xl-10,
.flex-col-xs-11,
.flex-col-sm-11,
.flex-col-md-11,
.flex-col-lg-11,
.flex-col-xl-11,
.flex-col-xs-12,
.flex-col-sm-12,
.flex-col-md-12,
.flex-col-lg-12,
.flex-col-xl-12,
.flex-col-auto {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.flex-col-seperator {
    width: 100%;
}
.flex-col-xs-1 {
    -ms-flex-preferred-size: 8.33333333%;
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}
.flex-col-xs-2 {
    -ms-flex-preferred-size: 16.66666667%;
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}
.flex-col-xs-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
}
.flex-col-xs-4 {
    -ms-flex-preferred-size: 33.33333333%;
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}
.flex-col-xs-5 {
    -ms-flex-preferred-size: 41.66666667%;
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}
.flex-col-xs-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
}
.flex-col-xs-7 {
    -ms-flex-preferred-size: 58.33333333%;
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}
.flex-col-xs-8 {
    -ms-flex-preferred-size: 66.66666667%;
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}
.flex-col-xs-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}
.flex-col-xs-10 {
    -ms-flex-preferred-size: 83.33333333%;
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}
.flex-col-xs-11 {
    -ms-flex-preferred-size: 91.66666667%;
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}
.flex-col-xs-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
}
.flex-col-xs-offset-1 {
    margin-left: 8.33333333%;
}
.flex-col-xs-offset-2 {
    margin-left: 16.66666667%;
}
.flex-col-xs-offset-3 {
    margin-left: 25%;
}
.flex-col-xs-offset-4 {
    margin-left: 33.33333333%;
}
.flex-col-xs-offset-5 {
    margin-left: 41.66666667%;
}
.flex-col-xs-offset-6 {
    margin-left: 50%;
}
.flex-col-xs-offset-7 {
    margin-left: 58.33333333%;
}
.flex-col-xs-offset-8 {
    margin-left: 66.66666667%;
}
.flex-col-xs-offset-9 {
    margin-left: 75%;
}
.flex-col-xs-offset-10 {
    margin-left: 83.33333333%;
}
.flex-col-xs-offset-11 {
    margin-left: 91.66666667%;
}
.flex-col-xs-offset-12 {
    margin-left: 100%;
}
.flex-col-auto {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
@media only screen and (min-width: 576px) {
    .flex-col {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-sm-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-sm-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-sm-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-sm-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-sm-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-sm-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-sm-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-sm-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-sm-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-sm-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-sm-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-sm-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-sm-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-sm-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-sm-offset-3 {
        margin-left: 25%;
    }
    .flex-col-sm-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-sm-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-sm-offset-6 {
        margin-left: 50%;
    }
    .flex-col-sm-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-sm-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-sm-offset-9 {
        margin-left: 75%;
    }
    .flex-col-sm-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-sm-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-sm-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 768px) {
    .flex-col {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-md-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-md-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-md-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-md-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-md-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-md-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-md-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-md-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-md-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-md-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-md-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-md-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-md-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-md-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-md-offset-3 {
        margin-left: 25%;
    }
    .flex-col-md-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-md-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-md-offset-6 {
        margin-left: 50%;
    }
    .flex-col-md-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-md-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-md-offset-9 {
        margin-left: 75%;
    }
    .flex-col-md-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-md-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-md-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 992px) {
    .flex-col {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-lg-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-lg-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-lg-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-lg-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-lg-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-lg-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-lg-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-lg-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-lg-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-lg-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-lg-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-lg-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-lg-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-lg-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-lg-offset-3 {
        margin-left: 25%;
    }
    .flex-col-lg-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-lg-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-lg-offset-6 {
        margin-left: 50%;
    }
    .flex-col-lg-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-lg-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-lg-offset-9 {
        margin-left: 75%;
    }
    .flex-col-lg-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-lg-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-lg-offset-12 {
        margin-left: 100%;
    }
}
@media only screen and (min-width: 1200px) {
    .flex-col {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -ms-flex-positive: 1;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        max-width: 100%;
        padding: 1rem;
    }
    .flex-col-xl-1 {
        -ms-flex-preferred-size: 8.33333333%;
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }
    .flex-col-xl-2 {
        -ms-flex-preferred-size: 16.66666667%;
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }
    .flex-col-xl-3 {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        max-width: 25%;
    }
    .flex-col-xl-4 {
        -ms-flex-preferred-size: 33.33333333%;
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }
    .flex-col-xl-5 {
        -ms-flex-preferred-size: 41.66666667%;
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }
    .flex-col-xl-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }
    .flex-col-xl-7 {
        -ms-flex-preferred-size: 58.33333333%;
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }
    .flex-col-xl-8 {
        -ms-flex-preferred-size: 66.66666667%;
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }
    .flex-col-xl-9 {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
        max-width: 75%;
    }
    .flex-col-xl-10 {
        -ms-flex-preferred-size: 83.33333333%;
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }
    .flex-col-xl-11 {
        -ms-flex-preferred-size: 91.66666667%;
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }
    .flex-col-xl-12 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .flex-col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .flex-col-xl-offset-2 {
        margin-left: 16.66666667%;
    }
    .flex-col-xl-offset-3 {
        margin-left: 25%;
    }
    .flex-col-xl-offset-4 {
        margin-left: 33.33333333%;
    }
    .flex-col-xl-offset-5 {
        margin-left: 41.66666667%;
    }
    .flex-col-xl-offset-6 {
        margin-left: 50%;
    }
    .flex-col-xl-offset-7 {
        margin-left: 58.33333333%;
    }
    .flex-col-xl-offset-8 {
        margin-left: 66.66666667%;
    }
    .flex-col-xl-offset-9 {
        margin-left: 75%;
    }
    .flex-col-xl-offset-10 {
        margin-left: 83.33333333%;
    }
    .flex-col-xl-offset-11 {
        margin-left: 91.66666667%;
    }
    .flex-col-xl-offset-12 {
        margin-left: 100%;
    }
}
.show {
    display: block !important;
}
.hide {
    display: none !important;
}
.show-xs {
    display: block !important;
}
.hide-xs {
    display: none !important;
}
@media only screen and (max-width: 575px) {
    .show-xs-only {
        display: block !important;
    }
    .hide-xs-only {
        display: none !important;
    }
}
@media only screen and (min-width: 576px) {
    .show-sm {
        display: block !important;
    }
    .hide-sm {
        display: none !important;
    }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .show-sm-only {
        display: block !important;
    }
    .hide-sm-only {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) {
    .show-md {
        display: block !important;
    }
    .hide-md {
        display: none !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .show-md-only {
        display: block !important;
    }
    .hide-md-only {
        display: none !important;
    }
}
@media only screen and (min-width: 992px) {
    .show-lg {
        display: block !important;
    }
    .hide-lg {
        display: none !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .show-lg-only {
        display: block !important;
    }
    .hide-lg-only {
        display: none !important;
    }
}
@media only screen and (min-width: 1200px) {
    .show-xl {
        display: block !important;
    }
    .hide-xl {
        display: none !important;
    }
}
.pfc-accordion {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}
.pfc-accordion-trigger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 23px;
    border-top: 1px #e2e2e2 solid;
    border-left: 3px transparent solid;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}
.pfc-accordion-trigger:hover {
    cursor: pointer;
}
.pfc-accordion-trigger:first-child {
    border-top: none;
}
.pfc-accordion-trigger.open {
    border-bottom: none;
    border-left: 3px #3082f9 solid;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.5s ease-out;
}
.pfc-accordion-trigger.open i {
    background: #3082f9;
    rotate: 90deg;
    color: #ffffff;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}
.pfc-accordion-trigger h4 {
    margin-top: 0;
    margin-bottom: 0;
}
.pfc-accordion-trigger i {
    font-size: 14px;
    color: #778186;
    background: #f1f4f5;
    padding: 10px;
    border-radius: 100px;
    margin-right: 15px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.pfc-accordion-content {
    padding: 30px 40px 40px;
    border-left: 3px transparent solid;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-out;
    transition: opacity 0.5s ease-out;
}
.pfc-accordion-content.expanded {
    border-bottom: none;
    opacity: 1;
    border-left: 3px #3082f9 solid;
    transition: opacity 0.5s ease-out;
    -webkit-transition: opacity 0.5s ease-out;
    -moz-transition: opacity 0.5s ease-out;
    -ms-transition: opacity 0.5s ease-out;
    -o-transition: opacity 0.5s ease-out;
}
.pfc-tab .mage-tabs-disabled,
.pfc-tab .mage-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}
.pfc-tab .item.title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.pfc-tab .item.title a {
    font-family: 'Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 20px;
    line-height: 1.2;
    padding: 0 20px;
    height: 78px;
    background: #f6f6f6;
    border: 1px #e2e2e2 solid;
    border-left: none;
    color: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.pfc-tab .item.title a i {
    font-size: 37px;
    margin-left: 20px;
}
.pfc-tab .item.title a .tab-title span {
    display: block;
}
.pfc-tab .item.title a .tab-title span.tab-label_secondary {
    font-family: 'Gotham-Book', Verdana, Arial, sans-serif;
}
.pfc-tab .item.title.active a {
    background: #ffffff;
}
.pfc-tab .item.title.active a::after {
    position: absolute;
    bottom: 0;
    height: 5px;
    background: #f59d08;
    width: 100%;
    right: 0;
    content: '';
}
.pfc-tab .item.title:first-child a {
    border-left: 1px #e2e2e2 solid !important;
}
.pfc-tab_medium .item.title a {
    font-size: 18px;
    padding: 0 20px;
    height: 68px;
}
.pfc-tab_medium .item.title a i {
    font-size: 32px;
    margin-left: 18px;
}
.pfc-tab_small .item.title a {
    font-size: 16px;
    padding: 0 20px;
    height: 58px;
}
.pfc-tab_small .item.title a i {
    font-size: 26px;
    margin-left: 16px;
}
.pfc-icon,
a.pfc-icon,
span.pfc-icon,
button.pfc-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif;
    font-size: 15.988px;
    font-weight: normal;
    height: 36px;
    width: 36px;
    font-family: 'pfc-font-icon';
    font-size: 20px;
    content: '';
    color: #000;
    padding: 0;
    margin: 0;
    vertical-align: bottom;
    border-radius: 50px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}
.pfc-icon i,
a.pfc-icon i,
span.pfc-icon i,
button.pfc-icon i {
    color: #000;
    font-size: 20px;
    z-index: 2;
}
.pfc-icon::before,
a.pfc-icon::before,
span.pfc-icon::before,
button.pfc-icon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: #f0f4f6;
    border-radius: 24px;
    z-index: 0;
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.pfc-icon:hover::before,
a.pfc-icon:hover::before,
span.pfc-icon:hover::before,
button.pfc-icon:hover::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}
.pfc-icon:after,
a.pfc-icon:after,
span.pfc-icon:after,
button.pfc-icon:after {
    font-weight: normal;
    font-family: 'pfc-font-icon';
    font-size: 24px;
    content: '\3a';
    color: #778186;
    padding: 0 0px 0 8px;
    margin: 0;
    vertical-align: bottom;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: 69px;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
}
.pfc-icon:hover,
a.pfc-icon:hover,
span.pfc-icon:hover,
button.pfc-icon:hover {
    text-decoration: none;
}

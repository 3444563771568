/*
BACKGROUND IMAGE BOX
*/

.backgroundBoxWrap {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1003;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.backgroundBox {
    /* width: 740px; */
    width: 735px;
    background: #fff;
    /* height: 500px; */
    /* height: 624px; */
    min-height: 613px;
    display: flex;
    flex-direction: column;
}

.backgroundBox > * {
    padding: 0 20px;
}

.imageBtn {
    box-sizing: content-box;
    margin: 0 15px 14px;
    margin-left: 0;
    border: 2px solid #ccccca;
    width: 162px;
    border-radius: 0;
    height: 91px;
    overflow: hidden;
}

.imageBtn:nth-last-child(-n + 4) {
    margin-bottom: 0 !important;
}

#app_wrapper .imageBtn.active,
#app_wrapper .imageBtn:hover {
    /* margin: 5px; */
    border: 2px solid orange !important;
}

.imageBtn img {
    display: inline;
    float: left;
    width: 162px;
    height: 91px;
}

button.productBtn {
    /* width: 154px; */
    width: 166px;
    height: 166px;
    margin: 0 8px 8px;
    margin-left: 0;
    border-radius: 0;
    border: 1px solid #ccc;
}

button.folderIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 15px;
    width: 95px;
    color: black;
    font-weight: normal;
    font: 400 13.3333px Arial;
}

button.folderIcon:hover,
button.folderIcon:active {
    font-weight: normal !important;
    font: 400 13.3333px Arial !important;
    background: transparent !important;
}

button.folderIcon img {
    margin-bottom: 10px;
}

button.productBtn:nth-child(4n) {
    margin-right: 0;
    margin: 0;
}

#app_wrapper .productBtn.active,
#app_wrapper .productBtn:hover {
    border: 1px solid orange;
}

.productBtn img {
    display: inline;
    float: left;
    /* border: 2px solid #ccccca; */
    width: auto;
    max-width: 100%;
    height: inherit;
    max-height: 100%;
    height: auto;
}

/* .productBtn.active img,
.imageBtn.active img {
  border: 2px solid orange;
} */

.productBtn:hover,
.productBtn:active,
.productBtn:focus,
.imageBtn:hover,
.imageBtn:active,
.imageBtn:focus {
    border: 0;
    background: none;
    outline: none;
}

.backgroundBox__body {
    align-content: baseline;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding-top: 23px;
    padding-left: 23px;
    /* padding-bottom: 6px; */
    padding-right: 0;
    position: relative;
}

.imageBtn:nth-child(4n) {
    margin-right: 0;
}

.backgroundScrollWrap {
    display: flex;
    /* flex: 1 1; */
    flex-wrap: wrap;
    height: 442px;
    overflow: hidden;
    overflow-y: auto;
    /* max-width: 703px; */
    width: 703px;
    /* background-color: #ff0; */
    z-index: 2;
    align-content: flex-start;
    /* padding: 15px; */
    /* padding: 0 15px 0 0; */
}

.backgroundScrollWrapline {
    position: absolute;
    border: 1px solid #ccc;
    width: 695px;
    height: 447px;
    top: 20px;
    left: 20px;
    z-index: 1;
}
.backgroundSelectModal {
    /* background: yellow; */
    /* width: 755px; */
    width: 766px;
    /* height: 595px; */
}
/* .backgroundSelectModal.plus_delete {
	height: 650px;
} */

.backgroundSelectModal .backgroundBox__title h4 {
    line-height: 1.8em;
}
.backgroundSelectModal .backgroundBox__body {
    flex: 0;
}
.backgroundSelectModal .backgroundScrollWrap {
    margin-top: -10px;
    width: 722px;
    height: 422px;
}
@-moz-document url-prefix() {
    .backgroundSelectModal .backgroundScrollWrap {
        width: 730px;
    }
}
.backgroundSelectModal .backgroundScrollWrapline {
    top: 10px;
    width: 728px;
    height: 428px;
}
.backgroundSelectModal .backgroundBox__title {
    box-sizing: content-box;
    flex: 0 0 53px;
}

.backgroundScrollWrap::-webkit-scrollbar {
    width: 6px;
    padding: 4px;
}

.backgroundScrollWrap::-webkit-scrollbar-track {
    padding: 10px;
    background: #fff;
    padding: 0 10px;
}

.backgroundScrollWrap::-webkit-scrollbar-thumb {
    background: #cccccc;
    border-radius: 4px;
    cursor: pointer;
}

.backgroundBox__body .productBtn img {
    float: none;
    max-height: 90%;
}

.backgroundBox__footer {
    flex: 0 0 50px;
    display: flex;
    justify-content: flex-end;
    /* border-top: 1px #f1f1f1 solid; */
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.backgroundBox__footer button {
    border-radius: 50px;
}

#app_wrapper .editBox .backgroundBox__footer.editBox__footer {
    padding: 30px 20px;
    border-top: none;
}

.backgroundBox__footer button {
    color: #000;
    margin-left: 10px;
    border: 1px solid #ccc;
    height: 35px;
    padding: 0 20px;
    background: #f2f2f2;
    border-radius: 30px;
    min-width: 100px;
    font-weight: normal;
}

.backgroundBox__footer button:hover,
.backgroundBox__footer button:active,
.backgroundBox__footer button:focus {
    border: 1px solid #ccc !important;
    transition: all 0.25s;
}

.backgroundBox__footer button.local__reset:hover,
.backgroundBox__footer button.local__reset:active,
.backgroundBox__footer button.local__reset:focus {
    border: none !important;
    color: #000;
}

.backgroundBox__footer button.ok {
    background: #fff;
    text-transform: uppercase;
}
.backgroundBox__footer button.upload.active {
    background: #fff;
}

.backgroundBox__footer button.local__reset {
    margin-left: 0;
    border: none;
    color: #15457e;
    padding: 0;
    background: transparent;
}

.upload-filename {
    font-size: 14px;
    font-family: Arial, sans-serif;
}

span.home {
    padding: 0 !important;
    width: 20px;
    float: left;
    margin-right: 10px;
    margin-top: -3px;
}

/* BACKGROUND COMPONENT CHANGE TO CSS */
.bbimage {
    padding: 10px 0 0 !important;
    margin: 0 20px 0;
}
.bbimage span {
    height: 20px;
    border-radius: 30px;
    padding: 0 0 0 10px;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    color: #19497a;
    cursor: pointer;
    font: 400 14px Arial;
}
.bbimage span:first-child {
    padding: 0;
}
.bbimage span.foldername:hover {
    text-decoration: underline;
}
.bbimage span:hover:after {
    text-decoration: none;
}
.bbimage span.noteffect::hover {
    text-decoration: none !important;
}
.bbimage span:last-child {
    color: #666666;
}
.bbimage span:last-child svg {
    fill: #666666;
}
.bbimage span:not(:last-child) svg {
    fill: #19497a;
}

.backgroundScrollWrap button {
    position: relative;
}
.backgroundScrollWrap button .delete-image-btn {
    display: none;
}
.backgroundScrollWrap button:hover .delete-image-btn {
    display: block;
}
.delete-image-btn {
    /* width: 40px;
	height: 50px;
	background: red; */
    display: block;
    position: absolute;
    right: 0;

    display: flex;
    padding: 0px 2px 2px 4px;
    align-items: flex-start;

    width: 35px;
    height: 27px;

    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    background: var(--neutral-greys-white, #fff);
}
.delete-image-btn > span {
    display: flex;
    width: 25px;
    height: 25px;
    display: block;
    padding: 4px 4px 5px 5px;
    justify-content: center;
    align-items: center;
}
.delete-image-btn img {
    width: 100%;
    height: 100%;
}

.confirm_overlay {
    position: fixed;
    z-index: 10000;
    background: rgb(0 0 0 / 50%);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.confirm_overlay_content {
    display: flex;
    width: 593px;
    padding: 20px 40px;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: absolute;
    transition: top 0.4s ease-out;
    z-index: 10001;
    margin-left: -292px;
    margin-right: auto;
    left: 50%;
}

.confirm_overlay_content p {
    color: #000;
    margin-bottom: 20px;
}
.confirm_overlay_content svg {
    margin-right: 8px;
}

#app_wrapper .btn_delete_all:focus,
#app_wrapper .btn_delete_all:focus-visible,
#app_wrapper .btn_delete_all {
    display: flex;
    padding: 10px 22px 10px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    border: 1px solid var(--primary-pfc-dark-blue-500, #1e3968) !important;

    color: var(--primary-pfc-dark-blue-500, #1e3968);

    /* UI text/Semi-bold Medium */
    font-family: Gotham;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 100%; /* 14px */
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    outline: none;
}
#app_wrapper .btn_delete_all:hover {
    background: transparent !important;
    color: #1e3968 !important;
}
#app_wrapper .btn_cancel {
    display: flex;
    padding: 10px 22px 10px 17px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    color: #1e3968;
    outline: none;
}
#app_wrapper .btn_cancel:hover,
#app_wrapper .btn_canel:focus,
#app_wrapper .btn_cancel:focus-visible {
    background: transparent !important;
    color: #1e3968 !important;
    outline: none;
}
#app_wrapper .btn_cancel:hover {
    background: #f1f4f5 !important;
}
#app_wrapper .btn_ok,
#app_wrapper .btn_ok:active,
#app_wrapper .btn_ok:focus,
#app_wrapper .btn_ok:focus-visible,
#app_wrapper .btn_ok:disabled {
    display: flex;
    width: 92px;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: #1e3968;
    color: #fff;
    outline: none;
}
#app_wrapper .btn_ok:hover {
    background-color: #4b6186;
}

#app_wrapper .upload-images-btn,
#app_wrapper .upload-images-btn:active,
#app_wrapper .upload-images-btn:focus,
#app_wrapper .upload-images-btn:hover {
    outline: none;
    width: 167px;
    height: 34px;
    padding: 10px 22px 10px 17px;
    border-radius: 22px;

    border: 1px solid #1e3968;
    background: #fff;

    color: var(--Primary-PFC-Dark-blue---500, #1e3968);

    /* UI text/Semi-bold Medium */
    /* font-family: Gotham; */
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 100%; /* 14px */
}

.after_delete_all_screen_bg {
    background: #eee;
    border: 1px solid #ccc;
}

.cc-title {
  background: #1e3968;
  height: 82px;
}
/* .cc-title > div {
    height: 52px;
} */
/* .cc-tlogo svg {
    height: 52px;
    width: 52px;
} */
.cc-ttitle {
  height: 36px;
  font-size: 30px;
  line-height: 36px;
}
.cc-tinput {
  color: #fff !important;
  /* font-family: Gotham; */
  font-size: 30px !important;
  font-style: normal;
  font-weight: 450;
  line-height: 36px; /* 120% */

  background: none !important;
  border: 0 !important;
  outline: none !important;
}
.cc-title button.pfc-bright-blue {
  width: 115px;
  height: 44px;
}

#presentation_container .action.btn-icon.btn-drp:active {
  background: #3082f9;
  color: #fff;
}

.action.btn-icon.btn-drp:hover {
  background-size: 0 100% !important;
}
.action.btn-icon.btn-drp {
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: center;
}

.action.btn-icon.btn-drp div {
  display: flex;
  align-items: center;
}

.action-btn-container .drp-container {
  font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}
.action-btn-container .drp-container label {
  display: flex;
  padding: 10px;
  white-space: nowrap;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  cursor: pointer;
  width: 217px;
}

.flipsnack-title {
  display: flex;
  gap: 10px;
}

.flipsnack-content {
  color: #000;
  white-space: pre-wrap;
  font-size: 12px;
  padding: 0 10px;
  line-height: 1;
  font-weight: 400;
  font-family: "Gotham-Book", Verdana, Arial, sans-serif;
}

.action-btn-container svg {
  transition: transform 0.4s ease-in-out;
}

.action-btn-container .drp-container label:hover {
  background: #f1f4f5;
  border-radius: 5px;
}
.action-btn-container .drp-container.hide {
  display: none;
}
.action-btn-container .drp-container.show {
  opacity: 1;
}
.action-btn-container .drp-container {
  opacity: 0;
  padding: 10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #0000001f;
  box-shadow: 0px 10px 15px -5px #0000001f;
  top: 55px;
  z-index: 3;
  transition: opacity 0.4s ease-in-out;
}
.action.btn-icon.btn-drp .arrow-container {
  margin-left: 20px;
  border-left: 1px solid #ffffff80;
  padding: 2px 0px 2px 12px;
  height: 16px;
}

.new-indicator {
  background: #f59d08;
  color: #fff;
  border-radius: 2px;
  padding: 4px 6px;
  font-size: 12px;
  font-family: "Gotham-bold", Verdana, Arial, sans-serif;
}

.rotate-180deg svg {
  transform: rotate(180deg);
}

.flipsnack-container,
.flipsnack-title {
  display: flex;
}

.flipsnack-container {
  flex-direction: column;
}

.bg-white {
    background: white;
}
.bg-white input:checked ~ .slider::before {
    text-indent: -9999px;
}
/* .line div > div {
    outline: 1px solid red;
} */
.line + .line {
    margin-bottom: 0 !important;
}
.cc-tborder {
    border-radius: 8px;
    border: 1px solid #e2e2e2;

    padding: 10px 20px 20px;
    height: 116px;
    position: relative;
}
.cc-tborder.m-b20 {
    height: auto;
}

.cc-label {
    height: 36px;
}
.main-colour {
    width: 74px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}
.accent-colour {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.vline {
    border: 1px solid #999999;
    margin: 10px 30px 0;
    padding-top: 10px;
    width: 1px;
    height: 73px;
    display: block;
}

.cc-tlist {
    background: #f6f6f6;
}
.cc-tlist .relative button:hover {
    background: none;
    border: 0;
}
.fz14.fw-325.lh21.gotham-book {
    margin-bottom: 0;
}
.cc-tutitle {
    height: 36px;
}
.cc-image img {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    display: block;
}
.layer {
    height: 116px;
}
.layer.m-b20 {
    height: auto;
}
.layer:after {
    content: ' ';
    display: block;
    height: 100%;
    width: 100%;
    background: rgba(233, 233, 233, 0.4);
    /* padding: 10px 20px 20px; */
    position: absolute;
    left: 0px;
    top: 0;
    border-radius: 8px;
}
.up-icon {
    width: 56px;
    height: 56px;
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 100%;
}

.cc-dialog {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 997;
    top: 0;
    left: 0;
    background: rgba(233, 233, 233, 0.7);
    max-width: 100% !important;
}
.cc-dialog h2 {
    height: 35px;
}
.cc-dialog.color-picker > .modal-inner-wrap {
    min-width: 615px;
    max-width: 615px;
    min-height: 405px;
    max-height: 405px;
}

.color-box {
    width: 60px;
    height: 60px;
    border: 1px solid black;
    position: absolute;
    left: 302px;
}
.fix_cmyk {
    /* display: none; */
    position: absolute;
    left: 392px;
    /* top: 5px; */
    /* outline: 1px solid green; */
}
.fix_cmyk span {
    display: block;
    /* position: absolute; */
    /* position: relative; */
    text-transform: uppercase;
    left: -69px;
    top: 0px;
    font-size: 16px;
    line-height: 16px;
    width: 10px !important;
    height: 40px !important;
    float: left;
    padding: 12px 0 0 10px;
    /* outline: 1px solid red; */
}
.fix_cmyk input {
    width: 49px !important;
    height: 40px !important;
    padding-left: 9px !important;
    padding-top: 3px !important;
    font-size: 16px !important;
    font-family: arial;
    margin-left: 20px !important;
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div label {
    top: 12px !important;
    font-size: 16px !important;
    line-height: 16px !important;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(9) {
    /* top: -122px; */
    position: absolute;
    /* top: -105px; */
    top: -90px;
    left: 87px;
    /* right: -85px; */
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div input {
    height: 40px !important;
    width: 49px !important;
    font-size: 16px !important;
    line-height: 16px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(5) {
    position: absolute;
    top: -145px;
    left: 170px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(6) {
    top: -145px;
    left: 170px;
}
.cmyk_tool .photoshop-picker .flexbox-fix:nth-child(1) > div:nth-child(2) > div:last-child > div:nth-child(7) {
    top: -145px;
    left: 170px;
}
/* .pfc-toggle .slider,
.pfc-toggle input:checked ~ .slider {
    width: 56px;
} */
.pfc-toggle input:checked ~ .slider:before {
    border: 0;
}

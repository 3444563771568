.contact_wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
}
.contact_logo_warpper {
    width: 220px;
    height: 120px;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);

    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
}

.contact_logo_warpper img {
    max-width: 100%;
    max-height: 100%;
}

.contact_name {
    font-size: 16px;
    font-weight: 400;
    width: 90%;
    text-align: center;
}

.contact_section {
    width: 300px;
    font-size: 14px;
}

.contact_section p {
    margin-bottom: 0;
    font-size: inherit;
    line-height: 18.2px;
    min-height: 18px;
}

.contact_section p span {
    display: inline-block;
    line-height: 1rem;
    width: 100%;
}
/* .contact_section .editable p:hover {
    outline: 1px dashed #000;
} */

.overvieew-pagination {
  /* flex: 0 0 50px; */
  display: flex;
  justify-content: center;

  padding: 10px 0;
  margin: 20px 20px 0;
  border-top: 1px #f1f1f1 solid;
}

.overvieew-pagination ul {
  display: flex;
  list-style: none;
}

.overvieew-pagination ul li button {
  color: #203866;
  width: 44px;
  height: 44px;
}

.overvieew-pagination ul li {
  border: 1px #e5e5e5 solid;
  color: #203866;
  margin: 5px;
  display: flex;
  justify-content: center;
}
.overvieew-pagination ul li:hover {
  border: 1px solid #004779;
  transition: all .25s ease-in-out;
}

.overvieew-pagination ul li.leftCurve {
  border-radius: 43px 0 0 43px;
  /* border: 0; */
}

.overvieew-pagination ul li.rightCurve {
  border-radius: 0 43px 43px 0;
  /* border: 0; */
}

.overvieew-pagination ul li.a {
  background: #00457c;
  color: #fff !important;
}

.overvieew-pagination ul li.a button {
  color: #fff !important;
}
.c-s-p-tab {
    height: 80px;
    padding: 20px 0;
    /* outline: 1px solid green; */
    flex-shrink: 0;
}
.c-s-p-tab > div {
    flex: 1;
    text-align: center;
    padding: 7px 0 0;
    /* outline: 1px solid red; */
}
.c-s-p-tab span {
    position: relative;
    font-size: 16px;
}
.c-s-p-tab > div.active span::after {
    content: ""; /* Required for the pseudo-element to render */
    position: absolute; /* Position absolutely to avoid affecting text flow */
    bottom: -15px; /* Adjust this value to control the spacing */
    left: 0; /* Start from the left of the container */
    width: 100%; /* Span the full width of the container */
    border-bottom: 4px solid #3082f9; /* Define the underline style */
}

.cc-footer {
    height: 135px;
    background: #fff;
    border-top: 1px solid #e2e2e2;
}
.cc-footer.collapse {
    height: 46px;
}
.cc-fstatus {
    height: 46px;
}

/* ITEM */
.cc-fitem {
    display: inline-flex;
    padding: 5px 5px 3px 0px;
    margin-right: 15px;
    align-items: flex-start;
    /* outline: 2px solid red; */
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    width: 171px;
    height: 110px;
    overflow: hidden;
    position: relative;
    border: 2px solid transparent;
    cursor: pointer;
}
.cc-fitem.active {
    border: 2px solid #3082f9;
}
.cc-fino {
    width: 20px;
    text-align: center;
}
.testThumb {
    width: 932px;
    height: 526px;
    -webkit-transform: scale(0.156);
    transform: scale(0.156);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
}

/* HIDE SLIDE */
.slide-hidden {
    position: absolute;
    top: 5px;
    left: 20px;
    background: rgba(255, 255, 255, 0.7);
    width: 146px;
    height: 82px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.slide-hidden > img {
    width: 26px;
    height: 26px;
}

/* OVERLAY */
.cc-faction {
    background: red;
    width: inherit;
    height: inherit;
    position: absolute;
    left: 0;
    top: 0;
    background: transparent;
}
.cc-faction.act button,
.cc-faction:hover button {
    display: block;
}
.cc-faction button {
    border: 0;
    background: transparent;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.cc-faction button svg:hover {
    fill: #ffffff;
}
.cc-faction button img {
    pointer-events: none;
}

/* COLLAPS BUTTON */
/* .cc-fbtn {
    width: 13px;
    height: 84px;
    height: 68px;
    position: absolute;
    top: 0;
    top: -40px;
    left: 50%;
    transform: rotate(-90deg);
    cursor: pointer;
} */
.cc-fbtn.close .cc-fba {
    transform: rotate(180deg);
    top: -6px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
}
.cc-fba {
    position: absolute;
    /* left: -5px;
    top: 24px; */
    left: 50%;
    top: -10px;
    margin-left: -6px;
    width: 12px;
    height: 12px;
    pointer-events: none;
}
.cc-fbb {
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    /* transform: rotate(90deg); */
    width: 84px;
    height: 14px;
    top: -13px;

    left: 50%;
    margin-left: -42px;
}

/* SCROLL */
.horizontal-scrollable-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    height: 200px; /* Set the desired height */
}

.horizontal-scrollable-list > * {
    flex: 0 0 auto;
}

/* FLEX */
.flex {
    display: flex;
}
.flex-shink-0 {
    flex-shrink: 0;
}
.flex-inline {
    display: inline-flex;
}
.flex-wrap {
    flex-wrap: wrap;
}
.justify-space-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: end;
}
.align-center {
    align-items: center;
}
.align-end {
    align-items: flex-end;
}
.flex-column {
    flex-direction: column;
}
.flex-row {
    flex-direction: row;
}
.flex-row-reverse {
    flex-direction: row-reverse;
}
.flex1 {
    flex: 1 0 50%;
}
.flex01 {
    flex: 1;
}
.flex01 svn {
    display: block;
}

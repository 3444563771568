body.usercollection-presentation-index .page-footer .footer,
.quotation-presentation-index .page-footer .footer {
    margin-top: 0;
}

body.usercollection-presentation-overview .page-footer .footer {
    margin-top: 0;
}

.quotation-presentation-index .page-main {
    padding-left: 0;
    padding-right: 0;
}

#presentation_container {
    position: relative;
    padding-bottom: 40px;
    /* min-height: 100vh !important; */
}

#presentation_container h3 {
    margin-top: 0;
}

/* #presentation_container .setting__wrap .setting__list .setting__element input[type=checkbox] 
{

    opacity: 1 !important;
    position: relative;
    z-index: 1;

} */

#pt-title h2 {
    font-family: 'Conv_Gotham-Black', Verdana, Arial, sans-serif;
    font-weight: 400;
    margin: 2px 0 0;
}

.navigation {
    z-index: 2;
}
.pf-full-backdrop {
    z-index: 1;
}
.setting__wrap {
    z-index: 4;
}
.setting_inside_wrap {
    z-index: 6;
}
.setting__overlay {
    z-index: 5;
}

#presentation_container button:active,
#presentation_container button:focus #presentation_container .mailsharevalue button:active,
#presentation_container .mailsharevalue button:focus,
#presentation_container .mailsharevalue button:hover {
    background: transparent;
    border: none;
    color: inherit;
}
/* #presentation_container button.strip__add__btn:active, 
#presentation_container button.strip__add__btn:focus
{
    border: 1px solid #ccc ;
    color: #000;
} */
/* #pt-title button:active, #pt-title button:focus 
{
    background: none ;
    border:none ;
} */

#presentation_container button.editbackgroundimage:active,
#presentation_container button.editbackgroundimage:focus {
    background: hsla(0, 0%, 100%, 0.6) !important;
}
.titletext p {
    /* line-height: 1 !important; */
    line-height: 0.9 !important;
    margin-bottom: 0 !important;
}
.titletext p span + span {
    margin-top: 3px;
    display: block;
}
.titletext p:nth-child(2) {
    padding-top: 4px;
}
/* #presentation_container .ecqEditbutton button:active, #presentation_container .ecqEditbutton button:focus,  #presentation_container .ecqEditbutton button:hover
{
    background: #fff;
} */

.editablebox_input[type='number'],
.editablebox_input[type='text'] {
    width: 64px;
    background: transparent;
    border: none;
    padding: 0 0 0 10px;
    height: 30px;
    font-size: 12px;
    box-shadow: none;
}

.EditableItemCell input[type='text']:disabled {
    opacity: 1;
}

.pf-content-menu li.highlight a:after {
    z-index: 1 !important;
}
.backgroundBox__title h4 {
    margin-top: 10px;
}

.font-gotham-book {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif !important;
}

.font-gotham-medium {
    font-family: 'Conv_Gotham-Medium', Verdana, Arial, sans-serif !important;
}

.font-gotham-bold {
    font-family: 'Conv_Gotham-Bold', Verdana, Arial, sans-serif !important;
}
.font-gotham-black {
    font-family: 'Conv_Gotham-Black', Verdana, Arial, sans-serif !important;
}

.font-arial-normal {
    font-family: Arial, sans-serif !important;
}

/* FONT FAMILY */
.gotham-black {
    font-family: "Gotham-Black", Verdana, Arial, sans-serif;
}
.gotham-bold {
    font-family: "Conv_Gotham-Bold", Verdana, Arial, sans-serif;
}
.gotham-medium {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}
.gotham-book {
    font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif;
}

/* FRON DYSON LESS */

.font-gotham_light {
    font-family: "Gotham-Light", Verdana, Arial, sans-serif;
    font-weight: 300;
    /*   // font-weight: 300 */
}
.font-gotham_book {
    font-family: "Gotham-Book", Verdana, Arial, sans-serif;
    font-weight: 325;
    /*   // font-weight: 325 */
}
.font-gotham_medium {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
    font-weight: 350;
    /*   // font-weight: 350 */
}
.font-gotham_bold {
    font-family: "Gotham-Bold", Verdana, Arial, sans-serif;
    font-weight: 400;
    /*   // font-weight: 400 */
}
.font-gotham_black {
    font-family: "Gotham-Black", Verdana, Arial, sans-serif;
    font-weight: 450;
    /*   // font-weight: 450 */
}
.font-gotham_ultra {
    font-family: "Gotham-Ultra", Verdana, Arial, sans-serif;
}

/* NOTE: ADMIN SETTING */
.inner-b-fmedium b {
    font-family: "Gotham-Medium", Verdana, Arial, sans-serif;
}

/* form element fix */
.text-bold_small input[type="text"] {
    font-family: inherit !important;
}
.text-semi-bold_medium input[type="text"] {
    font-family: inherit !important;
}
.text-semi-bold_small input[type="text"] {
    font-family: inherit !important;
    font-size: inherit;
}
.gotham-black input[type="text"] {
    font-family: inherit !important;
}

/* FONT WEIGHT */
.fw-normal {
    font-weight: normal;
}
.fw-325 {
    font-weight: 325 !important;
}
.fw-350 {
    font-weight: 350;
}
.fw-400 {
    font-weight: 400;
}
.fw-450 {
    font-weight: 450;
}

/* FONT SIZE */
.fz39 {
    font-size: 39px !important;
}
.fz19 {
    font-size: 19px !important;
}
.fz16 {
    font-size: 16px !important;
}
.backgroundBox__body .backgroundBox__body p.pz14,
.fz14 {
    font-size: 14px !important;
}
.fz13 {
    font-size: 13.33px !important;
}
.fz12 {
    font-size: 12px !important;
}
.fz11 {
    font-size: 11px !important;
}
.fz10,
.fz_10 {
    font-size: 10px !important;
}
.fz09,
.fz_9 {
    font-size: 9px !important;
}

/* LINE HEIGHT */
.line-height_0 {
    line-height: 0;
}
.line-height_10 {
    line-height: 10px;
}
.line-height_13 {
    line-height: 13px;
}
.lh16,
.line-height_16 {
    line-height: 16px;
}
.line-height_1rem {
    line-height: 1.6rem;
}
.lh21 {
    line-height: 21px;
}
.lh22 {
    line-height: 22.8px;
}
.lh24 {
    line-height: 24px;
}
.lh46 {
    line-height: 46.8px;
}
/* TEXT */
.text-uppercase {
    text-transform: uppercase;
}
.textalign-right {
    text-align: right;
}
.textalign-center {
    text-align: center;
}
.text-lowercase-first {
    text-transform: lowercase;
}
.text-lowercase-first::first-letter {
    text-transform: uppercase;
}

/* Text Wrap */
.break-word {
    word-wrap: break-word;
}

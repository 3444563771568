* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#pf-ptool {
    height: 100%;
    min-width: 460px;
    padding-right: 0;
    padding-left: 0;
}
html {
    -webkit-print-color-adjust: exact;
    /* -webkit-filter: opacity(1); */
}

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background: #d8e0e5; */
    /* background: #f1f1f1; */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* header {
  background: blue;
  min-height: 70px;
  color: #fff;
} */

.drawContainer {
    /* background: #f1f1f1; */
    min-width: 1200px;
    height: 100%;
}

.btn-text {
    display: none;
}

button {
    background: none;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
}

select:focus {
    outline: none;
    border: 0;
}
select:active,
button:focus,
button:active {
    outline: none;
    border: 0;
    background: none;
}
#ptool-bg,
#presentation_container {
    margin-top: -20px;
    height: 100%;
    /* background-image: url('./assets/background.jpg'); */
    /* background-image: url(assets/bgsvg1.svg); */
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADwCAMAAAD2Bf4HAAAAOVBMVEXX4eXX4uve5+7h6vDa5Ozy9vn3+Prt8vbp7vLr8PTl7PHb5uz0+Pro7vLv9Pf4+fzV4On+///X3+LtVQ0dAAAASUlEQVQY02MQZGBgYGQQAJFkQBY8kBsNMhGAzHggFxrkhUMOEEaDnGiQHQPywSErVsiDBNmgkB8I2ZB4ggxCDAzgkGMA04MSAABJ9APv6TTd1wAAAABJRU5ErkJggg==');
    background-repeat: repeat-x;
    /* background-repeat: no-repeat; */
    background-position: top center;
    background-color: #d8e0e5;
    /* background-size: contain; */
}
/* #ptool-bg:before, #presentation_container:before
{
  background-image: url(assets/bgsvg1.svg);
  width:100%;
  content:'';
  height:200px;
} */

#maincontent {
    max-width: 100% !important;
    /* min-height: 100vh !important; */
}

.absolute {
    position: absolute;
}
.relative {
    position: relative;
}
.position-right {
    right: 0;
}

/* OVERFLOW */
.overflow-hidden {
    overflow: hidden;
}

.cursor-pointer {
    cursor: pointer;
}
.word-break-all {
    word-break: break-all;
}
.word-break-word {
    word-break: break-word;
}

.display-child-block svg {
    display: block;
}
/* .main-page {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1920px !important;
    padding-left: 57px;
    padding-right: 57px;
    width: 100%;
} */

/* .c-s-p-element > div { */
/*     outline: 1px solid red; */
/* } */

.cc2-aside-panel-element {
  padding: 0 30px;
}
.cc2-style_wrap {
  /* background: red; */
  margin-bottom: 20px;
}

.cc2-color-panel {
  background-color: #eee;
  padding: 20px;
  position: relative;
}
.down-arrow {
  width: 0;
  height: 0;
  margin: 8px 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #eee;
  position: absolute;
  bottom: -20px;
  left: 127px;
}
.cc2-style-item {
  width: 68px;
  height: 68px;
  background-color: #fff;
  border: 2px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 20px;
}

.cc2-style-item.active {
  border-color: #3082f9;
}

.cc2-setting__divider {
  border-top: 1px #666 dashed;
  padding-top: 20px;
}

.highlight-item {
  width: 64px;
  height: 64px;
  background-color: #fff;
  border: 2px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 20px;
  padding: 4px;
}
.highlight-item.active {
  border-color: #3082f9;
}
.highlight-item > img {
  max-width: 60px;
  max-height: 60px;
}

/* COLOR */
.setting__element__color span,
.setting__element__colorpicker {
  /* width: 10px; */
  /* height: 30px; */
  /* background-color: red; */
  border: 1px solid #000;
}
.setting__element__colorpicker {
  width: 36px;
  height: 36px;
  display: block;
  margin-left: 10px;
}
.mainColor .setting__element__colorpicker {
  width: 56px;
  height: 36px;
  margin-left: 0;
}

.colorChoice > div {
  cursor: pointer;
  height: 20px;
  /* line-height: 20px; */
  font-size: 16px;
}
.colorChoice > div > SVG {
  margin-right: 10px;
}

/* COLOR :: SCHEME */
.colorScheme-item {
  width: 68px;
  height: 24px;
  padding: 2px;
  background: #fff;
  /* border: 2px solid red; */
  /* outline: 1px solid red; */
  margin-right: 20px;
}
.colorScheme-item > div {
  /* border: 1px solid blue; */
  /* padding: 2px; */
  width: 64px;
  height: 20px;
}
.colorScheme-item:nth-child(3n + 0) {
  margin-right: 0;
}
.colorScheme-item-color {
  width: 68px;
  padding: 0;
}
.colorScheme-item-color > span {
  height: 20px;
  width: 20px;
}
.colorScheme-item.active {
  padding: 0;
  border: 2px solid #3082f9;
}

/**/
.c-box-wrap {
  padding: 15px 20px 5px 20px;
  border: 1px solid #e2e2e2;
}
.color-deletable {
  position: relative;
}
.color-deletable .color-delete-btn {
  width: auto;
  height: auto;
  display: inline-flex;
  background: #fff;
  position: absolute;
  right: -17px;
  bottom: 12px;
  border-radius: 50%;
  padding: 3px;
  display: none;
  padding: 6px;
  border: 1px solid #00000033;
}
.color-deletable:hover .color-delete-btn {
  display: inline-flex;
}
button.action.bgColorNone {
  height: 34px;
  padding: 10px 22px 10px 17px;
  background: none !important;
  width: 100%;
}
button.action.bgColorNone:active {
  /* border: 1px solid red !important; */
  border: 1px #1e3968 solid !important;
}
#presentation_container button.action.bgColorNone:hover {
  background: none;
  border: 1px #1e3968 solid;
}

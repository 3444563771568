.backgroudSelectBox {
    /* width: 615px; */
    width: 766px;
    height: auto;
    /* height: 600px; */
    /* min-height: 380px; */
    min-height: 440px;
    background-color: #fff;
}
.backgroudSelectBox .backgroundBox__title {
    min-height: 50px;
}

.backgroudSelectBox .backgroudSelectBox_body > div.flex,
.backgroudSelectBox_body {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: space-evenly;
}
.flex.justify-center.align-center.p-50 {
    padding: 0 15px;
}
.upload-choice {
    font-family: Arial, sans-serif;
}
.upload-choice h4 {
    line-height: 1.5;
    font-size: 18px;
}
.backgroudSelectBox .backgroudSelectBox_body > div.flex > div {
    /* margin: 0 20px; */
    text-align: center;
    flex: 1;
}
.backgroudSelectBox .backgroudSelectBox_body > div.flex > div h4 {
    margin: 0;
    font-weight: bold;
    font-family: Arial, sans-serif;
}
.backgroudSelectBox button {
    font-size: 14px;
    line-height: 18px;
    color: #231f20;
    border-radius: 0;
}
.backgroudSelectBox .backgroundBox__footer button {
    border-radius: 50px;
}
.backgroudSelectBox button:hover {
    background: none;
}
.backgroudSelectBox button {
    font-family: Arial, serif;
    font-size: 14px;
    font-weight: normal;
}
.backgroudSelectBox button h4 {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1rem;
}
.backgroudSelectBox button > div {
    width: 132px;
    height: 132px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 25px #e8e8e8;
    /* box-shadow: 0px 0px 20px #dfdfdf; */
}
.backgroudSelectBox button > div:hover {
    /* box-shadow: 0px 0px 25px #f6f6f6; */
    box-shadow: 0 0 25px #ccc;
    transition: all 0.25s;
}

#preview {
    height: 75px;
    padding-right: 20px;
}

.upload-form {
    width: 415px;
    margin: 0 auto;
    position: relative;
}
.upload-form .uploadPreview {
    height: 115px;
    display: flex;
    align-items: center;
    /* outline: 1px solid red; */
}
.uploadPreview .upload-button-warp {
    height: 60px;
    justify-content: left;
}
p.upload-err {
    position: absolute;
    top: 15px;
    width: 100%;
    font-size: 12px;
    /* border: 1px solid red; */
    text-align: center;
    line-height: 1.6rem;
}
p.upload-err span {
    display: block;
}
p.upload-err + .upload-button-warp > .upload-button {
    margin-top: 30px;
}
p.upload-err + .uploadPreview {
    margin-top: 30px;
}
.upload-button-warp {
    height: 115px;
    max-height: 115px;
    overflow: hidden;
    /* outline: 1px solid black; */
}
.upload-button {
    border: 1px solid #cccccc;
    padding: 10px 22px 10px 17px;
    border-radius: 25px;
    height: 34px;
    line-height: 14px !important;
    display: flex;
    text-align: center;
    justify-content: flex-start;
    min-width: 185px;
    font-size: 14px;
    font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif !important;
}

.backgroudSelectBox button.upload-button {
    border-radius: 25px;
}
.cc-upload-button img {
    margin-right: 15px;
}
.upload-button:hover,
.upload-button:active,
.upload-button:focus {
    border: 1px solid #034883 !important;
    transition: all 0.25s;
}
#cc-upload-file {
    display: none;
    position: absolute;
    top: 0;
    opacity: 0;
}
.upload-requirements {
    background-color: #f2f2f2;
    padding: 20px 22px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
}
.upload-requirements h4 {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    font-family: "Conv_Gotham-Medium", Verdana, Arial, sans-serif;
}
.upload-requirements ul {
    list-style: none;
    margin-left: 0;
    margin-top: 12px;
    font-size: 14px;
    font-family: Arial, serif;
    margin-bottom: 0;
    margin-top: 5px;
}
.upload-requirements ul li {
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: normal;
    font-family: Arial, sans-serif;
}
.upload-requirements ul li:last-child {
    margin-bottom: 0;
}
.upload-err {
    color: red;
}

.upload-note {
    font-family: Arial, serif;
    font-size: 14px;
    line-height: 1.4em;
    padding: 15px 18px;
    border: 1px solid #f2f2f2;
    margin-top: 20px;
}

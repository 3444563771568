.slideTemplate_list {
    margin-top: 10px;
    text-transform: capitalize;
}
.slideTemplate_list .img {
    width: 284px;
}
.slideTemplate_list img {
    border: 2px solid #fff;
    display: block;
    cursor: pointer;
    /* width: 284px; */
    /* height: 160px; */
    /* min-height: 125px; */
    width: 216px;
    height: 121px;
    margin: 0 auto;
}

.slideTemplate_list div {
    width: 100%;
    display: block;
    text-align: center;
}

.slideTemplate_list img:hover {
    border: 2px solid #f59d08;
}

.slideTemplate_list label {
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif !important;
    font-size: 14px;
}

.action-template-image svg {
    width: 71px;
    height: 40px;
}

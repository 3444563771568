.overview {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    max-width: 1134px;
    margin: 0 auto;
}

.removeBgImg {
    background-image: none !important;
    background-color: #fff !important;
}

.overview__tabs {
    display: flex;
    align-items: flex-end;
}
.overview__tab {
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
    height: 39px;
    line-height: 40px;
    position: relative;
    margin: 0;
    border: 1px solid #000;
    background-color: #eee;
}
.overview__tab.active {
    border: 0;
    border: 1px solid #000;
    border-bottom: 0;
    background: #fff;
    height: 41px;
}
/* .overview__tab:not(:first-child) {
border-left: 0;
} */
.overview__tab.active:not(:first-child) {
    border-left: 1px solid #000;
}
.overview__tab.active:first-child {
    border-right: 1px solid #000;
}
.overview__tab:first-child {
    border-right: 0;
}
.overview-header {
    flex: 0 0 65px;
    align-items: flex-end;

    /* border: 1px double red; */
}

.overview-header {
    display: flex;
    justify-content: space-between;

    /* padding: 10px 0; */
    margin: 0 0 20px;
    /* border-bottom: 1px #f1f1f1 solid; */
    /* border-bottom: 1px #000 solid; */
    height: 50px;
}
.overview-header h3 {
    font-size: 16px;
    font-family: arial;
    font-weight: normal;
    text-transform: capitalize;
    /* outline: 1px red solid; */
    /* line-height: 2em; */
}

.overview__action {
    /* outline: 1px green solid; */
    border-bottom: 1px solid #000;
    display: flex;
    height: 39px;
    height: 45px;
    margin-top: 12px;
    flex: 1;
    justify-content: flex-end;
}
.overview__action section:first-of-type {
    padding: 0 20px 0 0;
}

.overview__action section:first-of-type label {
    padding: 0 15px 0 0;
}
.overview__action label {
    font-family: arial;
    font-size: 12px;
    padding: 0 15px 0 0;
    width: auto;
    align-items: center;
    justify-content: flex-end;
}
.overview__action section {
    display: flex;
    height: 32px;
    font-family: arial;
    font-size: 12px;
}
.overview__action section.reverse {
    flex-direction: row-reverse;
}

.overview-list {
    /* -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1; */
    width: 100%;
    max-width: 1174px;
    margin: 0 auto;
}

.end_customer_quotes .list-wrap {
    background: #071639;
}
.end_customer_quotes .list-wrap > div {
    padding: 0;
}
.end_customer_quotes .list-image img {
    width: auto;
}
.grid-list {
    /* display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */

    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
}

.list-wrap {
    /* box-sizing: border-box;
    margin-bottom: 20px;
    margin: 0 0px 20px;
    min-height: 185px;
    margin-right: 30px;
    width: 164px;
    outline: 1px solid #cccccc;
    display: flex;
    flex-direction: column; */

    box-sizing: border-box;
    margin: 0 15px 20px 15px;
    min-height: 185px;
    width: 164px;
    outline: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
}

.list-wrap:hover {
    cursor: pointer;
}

.list-wrap > div {
    position: relative;
    padding: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.list-image {
    width: 100%;
    height: 90px;

    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.list-image img {
    height: inherit;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
}
.end_customer_quotes .list-image img {
    padding: 10px;
}
.list-action {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    background: #fff;

    width: 33px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-action button {
    line-height: 1;
    width: inherit;
    height: inherit;
}

.list-wrap:hover .list-action {
    opacity: 1;
}

.list-action button + button {
    margin-left: 5px;
}
.list-action svg {
    fill: #333333;
}
.list-info {
    /* outline: 1px solid pink; */
    margin-top: 20px;
    font-size: 14px;
    text-align: center;
    color: #666666;
    padding-bottom: 10px;
    height: calc(100% - 110px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: arial;
    font-size: 14px;
}
.list-info p {
    font-weight: bold;
    color: #000;
    margin-bottom: 0px;
    padding: 0 10px;
    height: 35px;
    line-height: 17px;
    font-size: 14px;
    /* word-break: break-all; */

    /* white-space: nowrap; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: block; */
}

.end_customer_quotes .list-info,
.end_customer_quotes .list-info p {
    color: #fff;
}
.overview .overview__action button {
    margin-left: 10px;
}
.end_customer_quotes .list-image {
    background-color: #fff;
}
.list-action button:hover,
.list-action button:active,
.list-action button:focus,
.overview button:hover,
.overview button:active,
.overview button:focus {
    background: none;
    border: 0;
    outline: none;
}

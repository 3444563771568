/* MARGIN */
#presentation_container .mt20 {
    margin-top: 20px;
}
.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.mt30 {
    margin-top: 30px;
}

.mr5 {
    margin-right: 5px;
}
.mr5 {
    margin-right: 5px;
}
.mr8 {
    margin-right: 8px;
}
.mr10 {
    margin-right: 10px;
}
.mr12 {
    margin-right: 12px;
}
.mr14 {
    margin-right: 14px;
}
.mr15 {
    margin-right: 15px;
}
.mr20 {
    margin-right: 20px;
}
.mr30 {
    margin-right: 30px;
}
.mb60 {
    margin-bottom: 60px;
}
.mb30 {
    margin-bottom: 30px;
}
.mb20 {
    margin-bottom: 20px;
}
#presentation_container .mb15,
.mb15 {
    margin-bottom: 15px;
}
.mb8 {
    margin-bottom: 8px;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb12 {
    margin-bottom: 12px;
}
.m-b_60 {
    margin-bottom: 60px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb0 {
    margin-bottom: 0;
}
.ml5 {
    margin-left: 5px;
}
.ml10 {
    margin-left: 10px;
}
.my20 {
    margin: 20px 0;
}

/* PADDING */
.pt-10 {
    padding-top: 10px;
}
.pt15 {
    padding-top: 15px;
}
.pt-20 {
    padding-top: 20px;
}
.pt5 {
    padding-top: 5px;
}
.pr20 {
    padding-right: 20px;
}
.backgroundBox__body p.py20,
.py20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.py14 {
    padding-top: 14px;
    padding-bottom: 14px;
}
.py10,
.py {
    padding-top: 10px;
    padding-bottom: 10px;
}
.py15 {
    padding-top: 15px;
    padding-bottom: 15px;
}
.py20 {
    padding-top: 20px;
    padding-bottom: 20px;
}
.px25 {
    padding-left: 25px;
    padding-right: 25px;
}
.px30 {
    padding-left: 30px;
    padding-right: 30px;
}
.px20 {
    padding-left: 20px;
    padding-right: 20px;
}
.px {
    padding-left: 15px;
    padding-right: 15px;
}
.p06 {
    padding: 6px;
}
.p20 {
    padding: 20px;
}
.p-t_20 {
    padding-top: 20px;
}
.pt110 {
    padding-top: 110px;
}
.pr0 {
    padding-right: 0;
}
.pr10 {
    padding-right: 10px;
}
.pr14 {
    padding-right: 14px;
}
.p-r_20 {
    padding-right: 20px;
}
.pb10 {
    padding-bottom: 10px;
}
.pb20 {
    padding-bottom: 20px;
}
.pl10 {
    padding-left: 10px;
}
.pl30 {
    padding-left: 30px;
}
.p5 {
    padding: 5px;
}

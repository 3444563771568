.edittogglewrap {
    padding: 20px 0 0;
}
.edittoggle {
    width: 30px;
    height: 12px;
    border-radius: 6px;
    background: #999;
    position: relative;
    cursor: pointer;
    top: 1px;
}
.edittoggle span {
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid #cacaca;
    border-radius: 9px;
    background: #fff;
    position: absolute;
    top: -3px;
    box-shadow: 0 0 3px #a9a9a9;
}
.edittoggle.on {
    background: #8ccdef;
}
.edittoggle.on span {
    background: #044680;
    border: 1px solid #013a6c;
}
.edittoggle.on span {
    right: 0;
}
.edittoggle.off span {
    left: 0;
}
.backgroundBox__body p.p-edittoggle {
    padding: 0;
    margin-left: 10px;
}
.cc-action {
    background: #fff;
    border-bottom: 1px solid #e2e2e2;
    /* color: #1e3968; */
    height: 76px;
    z-index: 1;
}
.cc-action > div {
    height: 36px;
}

.cc-alist {
    list-style: none;
    padding: 0;
}

.cc-alist li {
    display: flex;
    align-items: center;
    border-right: 1px solid #ccc;
    padding-right: 20px;
    margin-left: 20px;
}
.cc-alist li:first-child {
    margin: 0;
}
.cc-alist li:last-child {
    border: 0;
}
.cc-alist span {
    display: flex;
    justify-self: center;
    align-items: center;
    margin-right: 10px;
}

#presentation_container .cc-aright button:hover {
    border: 1px solid transparent;
    background: none;
}
.cc-aright span {
    margin-right: 8px;
}
/**
* old action bar styles
* TODO:: need to check and test in older presentation types
*/
.localmen-template-list {
    display: block;
    height: 42px;
    min-height: 42px;
    max-height: 42px;
    width: 73px;
    min-width: 73px;
    max-width: 73px;
    border: 1px solid #e1e1e1;
    overflow: hidden;
}
.localmen-template-list.active {
    border-color: orange;
}
.localmen-template-list img {
    height: 40px;
    width: 71px;
    max-height: 40px;
    min-height: 40px;
    max-width: 71px;
    min-width: 71px;
    display: inherit;
    cursor: pointer;
    overflow: hidden;
}

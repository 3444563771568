/* .selector { 
  outline: 1px solid green;
} */
.selector label {
  /* line-height: 36px; */
  height: inherit;
  display: flex;
  align-items: center;
}

.selector select,
.selector select:focus {
  /* height: 36px; */
      width: 150px;
      height: 32px;
      border: 1px solid #999;
      border-radius: unset;

  padding: 0 5px;
  padding-right: 30px;
  /* border: 1px #f1f1f1 solid; */
  height: inherit;
}

.selector select.size {
  width: 94px;
}
.selector.reverse select {
  width: 70px;
  padding-right: 0;
}
.selector.reverse label {
  padding-left: 5px;
}
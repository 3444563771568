.message_overlay {
    background: rgba(0, 0, 0, 0.5);

    /* position: absolute; */
    position: fixed;
    z-index: 1004;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
}
.message_box {
    min-width: 400px;
    min-height: 120px;

    background: #fff;
    padding: 20px 40px;
    transition: top 0.4s ease-out;

    position: absolute;
    top: -100%;
}
.message_box p {
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
}

/* .message_footer button.btn-cancel, */
/* #presentation_container .message_footer button.btn-cancel:active { */
/*     display: flex; */
/*     padding: 10px 22px 10px 17px; */
/*     justify-content: center; */
/*     align-items: center; */
/*     border-radius: 17px; */
/*     color: #1e3968; */
/*     outline: none; */
/* } */
/* .message_footer button.btn-cancel > svg { */
/*     margin-right: 8px; */
/* } */
.message_footer button.btn-ok,
#presentation_container .message_footer button.btn-ok:active {
    font-size: 14px;
    display: flex;
    width: 92px;
    padding: 10px 22px;
    justify-content: center;
    align-items: center;
    border-radius: 17px;
    background: #1e3968;
    color: #fff;
    outline: none;
}
.message_footer .btn-cancel svg {
    margin-right: 8px;
}

.cc2-margin {
    background: red;
}

.cc2-aside-panel-margin {
    padding: 20px 30px;
}

.margin-input {
    /* LAYOUT */
    display: flex;
    width: 57px !important;
    height: 40px !important;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    margin-right: 5px;
    /* STYLE */
    border: 1px solid #e2e2e2;
    background: #fff;
    text-align: right;
}

#presentation_container .margin-ok-btn,
#presentation_container .margin-ok-btn:active,
#presentation_container .margin-ok-btn:focus {
    /* LAYOUT */
    display: flex;
    padding: 14px 25px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    /* STYLE */
    background: #1e3968;
    border-radius: 22px;
}

.margin_bottom_text {
    width: 100%;
    background-color: #dfede0;
    position: absolute;
    bottom: 0px;
    left: 0;
    text-align: center;
    padding: 18px;
    font-size: 12px;
    color: #006200;
    border-top: 1px solid #cadfd0;
}

/* .cc2-aside-panel-design { */
/* 	padding: 20px 30px; */
/* } */
.c-s-p-element ul {
    list-style: none;
    padding: 0;
}
.c-s-p-element li {
    margin-bottom: 10px;
}

/* .cc2-side-design-list li:last-child { */
/*     margin-bottom: 0; */
/* } */
.font-color {
    /* LAYOUT */
    width: 54px;
    height: 36px;
    /* STYLE */
    border: 1px solid var(--neutral-greys-black, #000);
    background: #fff;
}

.setting__element {
    height: 555px;
    /* 545px; */
    /* width: 261px; */
    /*  overflow-y: hidden;*/
    overflow-x: hidden;
    overflow-y: auto;
}
.setting__element__themes {
    height: 505px;
}

/* .setting__element:hover {
  overflow-y: auto;
} */

.setting__element__wrap {
    /* width: 251px; */
    width: 100%;
    padding: 20px;
    background: #f2f2f2;
    /* padding-right: 10px; */
}
/* .setting__element__wrap > div {
  outline: 1px solid red;
} */

h3.setting__element__title {
    color: #000000;
    font-style: normal;
    font-weight: 450;
    font-weight: bold;
    font-size: 16px;
}
/* 
* Left menu, element panel
* list have different space between second and last item
*/
.ele-list-design > div:nth-child(2) {
    margin-bottom: 30px !important;
}
.ele-list-design > div:last-child {
    margin-top: 30px;
}
.ele-list-design > div:nth-last-child(2) {
    margin-bottom: 0;
}

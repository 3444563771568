/* COMMON */

#preventevent {
    pointer-events: none;
}

#preventevent svg {
    display: block;
}

#texttext {
    width: 100%;
    line-height: 1;
    overflow: hidden;
    resize: none;
    padding: 0;
    line-height: 1.3;
    height: auto;
    /* border: 1px solid #ddd; */
    border: none;
    background: none;
}
#texttext:focus,
#texttext:focus-visible {
    outline: none;
}

.pointer_events_none {
    pointer-events: none;
}

.fullHeight {
    height: 100%;
}

.titletext:hover .textWrap {
    outline: 1px dashed #000;
}
.titletext .textWrap.flexStart {
    justify-content: flex-start;
}
.titletext .icon_pencil_title_text {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 27px;
    align-items: center;
    justify-content: center;
    display: none;
    border-left: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    background: #fff;
    border-radius: 0;
}
.titletext:hover button.icon_pencil_title_text {
    display: block;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
}

/* 
BACKGROUND IMAGE 
PRESENTOR AND CUSTOMER LOGO
*/

.backgroundimage::after,
.slidelogos::after {
    content: ' ';
    position: absolute;
    top: -2px;
    /* for outside border */
    left: -2px;
    right: -2px;
    bottom: -2px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

.slidelogos2::after {
    content: ' ';
    position: absolute;
    top: -11px;
    /* for outside border */
    left: -11px;
    right: -2px;
    bottom: -10px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

#presentation_container .backgroundimage:hover::after,
.slidelogos:hover::after,
.slider-bottom-logo:hover::after {
    border: 2px solid #f59d08;
}

.backgroundimage .editbackgroundimage {
    padding: 0px;
    background: rgba(255, 255, 255, 0.6);
    display: none;
    height: 36px;
    /* margin-left: 80px!important; */
    border-radius: 36px;
    font-size: 14px;
    font-weight: 400;
    color: #000;
    position: absolute;
    bottom: 30px;
    left: 0;
    margin-left: 40%;
}

.editbackgroundimage span {
    display: block;
    position: relative;
}

.editbackgroundimage span:after {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #000;
    position: relative;
    bottom: -3px;
    display: block;
}

.backgroundimage:hover .editbackgroundimage {
    display: inline-block;
    padding: 0 15px;
}

/** Firefox fix */

/* .slidelogos {
  overflow: hidden;
} */

/* - LOGOS HOVER BUTTONS */

.productImages .logo-action,
.slidelogos .logo-action {
    top: 0px;
    right: 0;
    height: 27px;
    align-items: center;
    display: none;
    padding: 0 6px;
    z-index: 0;
    background: #fff;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.slidelogos2 .logo-action {
    top: -10px;
    right: 0;
    height: 29px;
    align-items: center;
    display: none;
}

.productImages:hover .logo-action,
.slidelogos:hover .logo-action {
    display: flex;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
}

.productImages .logo-action button,
.slidelogos button {
    background: #fff;
    /* width: 25px; */
    /* padding: 2px; */
    width: 17px;
    height: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: unset;
    margin-right: 8px !important;
}

.productImages .logo-action button:last-child,
.slidelogos button:last-child {
    margin-right: 0 !important;
}

.productImages .logo-action button img {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;
}

/*
rect
# image slice
*/

.rect-action {
    height: 27px;
    top: 2px;
    right: 2px;
    align-items: center;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    display: none;
    padding: 0 6px !important;
    background: #fff;
    z-index: 1;
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.rect-action button {
    width: 17px;
    height: 25px;
    background: #fff;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border-radius: 0;
    margin-right: 8px !important;
}

.rect-action button:last-child {
    margin-right: 0 !important;
}

.rect-action button:hover,
.rect-action button:active,
.rect-action button:focus {
    background: #fff !important;
    border-radius: 0;
}

.rect:hover .rect-action {
    display: flex;
}

.rect::after {
    content: ' ';
    position: absolute;
    top: 0px;
    /* for outside border */
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 2;
    /* background-color: red; */
    /* to make sure hovering on the background doesn't trigger color change */
}

.rect:hover::after {
    border: 2px solid #f59d08;
}

.rect.textfix::after {
    left: -148px;
}

.availablecolor {
    outline: 1px solid #ddd;
    display: flex;
}

.product_bottom_logo img,
.availablecolor img {
    width: 100%;
    height: 100%;
}

.slider-bottom-logo img {
    /* 
  PFSTOR-1242 to fix this
  width: 90% !important; 
  */
    height: auto;
    /* max-width: 100%; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.slider-bottom-logo .logo-action button img {
    position: relative;
    top: auto;
    bottom: auto;
    left: auto;
    transform: initial;
}

.textEditable .flex.text.Wrap {
    min-height: 11.5px;
}

.textEditable .flex.text.Wrap:hover {
    outline: 1px dashed #000;
    align-self: baseline;
    min-height: 20px;
}

.textEditable input {
    height: 30px;
    border: 0;
}

.textEditable input:focus,
.textEditable input:active {
    outline: none;
}

.textEditable textarea {
    resize: none;
}

#textContainer {
    word-break: normal;
}

.contactText #textContainer {
    word-break: normal;
}

.contactText #textContainer p span {
    line-height: 24px;
    display: block;
}

.product-logo:after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.product-logo .logo-action {
    top: 0;
}

.productImages {
    display: block !important;
    text-align: center !important;
}

.productImages img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
    margin: auto !important;
    display: inline-block;
    position: absolute;
    top: 49.9%;
    transform: translate(-50%, -49.9%);
    left: 50%;
    right: 50%;
}

/* .productImages::after
{
  content: ' ';
  position: absolute;
 
  border: 1px solid transparent;
  pointer-events: none;
  height: 100%;
  width: 100%;
 
} */

.productImages:hover {
    outline: 2px solid #f59d08;
}

.productImages .logo-action {
    background: #fff;
}

/* .productImages .logo-action button , .slider-bottom-logo .logo-action button
{
  padding: 0 3px;
} */

.productImages .logo-action button:hover,
.productImages .logo-action button:focus,
.productImages .logo-action button:active,
.slider-bottom-logo .logo-action button:hover,
.slider-bottom-logo .logo-action button:active,
.slider-bottom-logo .logo-action button:focus {
    background: #fff !important;
    border: none;
}

.nofocus input {
    border: 0;
}

.nofocus input:focus {
    outline: none;
}

.finalAction::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 10;
    border: 2px solid #f59d08;
    left: -146px;
    /* background: red; */
}

.finalAction .rect-action {
    display: block;
}

.ImagefinalAction::after {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0;
    bottom: 0px;
    /* background: #eee; */
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 10;
    border: 2px solid #f59d08;
    left: -0px;
    /* background: red; */
}

.ImagefinalAction .rect-action {
    display: flex;
}

.nohoveryellow {
    /* cursor: pointer; */
    display: flex;
    /* justify-content: flex-end; */
    /* display: none; */
}
.nohoveryellow::after {
    display: none;
}
/* .nohoveryellow:hover {
    background: rgba(0, 0, 0, 0.1);
} */
.nohoveryellow button {
    /* display: none; */
    /* background: #ccc; */
    border-radius: 32px;
    padding: 0 15px;
    height: 32px !important;
    min-width: 32px;
    margin-top: 4px;
    margin-right: 5px;
    font-weight: 400;
    color: inherit;
    display: flex;
    overflow: hidden;
    width: 190px;
}
.nohoveryellow:hover button {
    /* display: block; */
    background-color: #e5e5e5;
}
.nohoveryellow button span.edittext {
    display: none;
    line-height: 32px;
}
.nohoveryellow:hover button span {
    display: block;
}
.nohoveryellow button i {
    line-height: 32px;
    margin-right: 10px;
    line-height: 32px;
    /* font-family: 'pfc-font-icon'; */
    /* content: '\41'; */
    /* height: 32px; */
    /* width: 32px; */
    /* color: inherit; */
}
.nohoverbackground > button {
    display: none;
    text-indent: -9999em;
}

/* TITLE TEXT */
.titletext {
    display: flex;
}

.titletext .textWrap:hover {
    outline: 1px dashed #000;
}

.titletext .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}
.titletext textarea,
.titletext textarea:focus {
    width: 100%;
    background: transparent;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    outline: 0;
}

.c-p-t-list {
    list-style: none;
    padding: 0;
    padding-left: 0 !important;
}
.c-p-t-list li {
    border: 1px solid #eee;
    padding: 2px 10px 2px 2px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.c-p-t-list li div {
    width: 99px;
    /* background: red; */
    height: 100%;
}
.cc2-aside-panel-theme {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0 30px; */

    /* background: #ccc; */
    height: 90%;
    overflow: hidden;
}

.cc2-aside-panel-theme-tabs {
    width: 100%;
    /* 284px; */
    display: flex;
    /* background-color: #ccc; */
    height: 90px;
}

.cc2-aside-panel-theme-tabs > * {
    flex: 1;
    text-align: center;
    /* height: 80px; */
    /* background-color: #f00; */

    font-size: 16px;
    font-style: normal;
    font-weight: 350;
    line-height: 120%; /* 19.2px */
}
.cc2-aside-panel-theme-tabs div span {
    position: relative;
    /* border-bottom: 3px solid orange; */
    /* height: 50px; */
    /* text-underline-offset: 1.6em; */
    /* text-decoration: underline; */
    /* display: block; */
    line-height: 90px;
    cursor: pointer;
}
.cc2-aside-panel-theme-tabs > div.active span:after {
    content: " ";

    width: 100%;
    position: absolute;
    left: 0;
    top: 19px;
    bottom: 8px;
    border-width: 0 0 2px;
    border-style: solid;
    height: 8px;
    border-color: #f59d08;
}

.cc2-aside-panel-theme-ul {
    width: 100%;
    /* width: 284px; */
    /* background: #f00; */
}

.cc2-aside-panel-theme-ul li {
    list-style: none;
    display: flex;
    height: 50px;
    margin-bottom: 10px;
    border: 1px #ccc solid;
    background: #fff;

    display: flex;
    height: 50px;
    padding: 2px 10px 2px 2px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.cc2-aside-panel-theme-ul li:last-child {
    margin-bottom: 0;
}

.c-p-t-list li.active,
.cc2-aside-panel-theme-ul li.active {
    border: 2px solid #3082f9;
    background-position: 0 0 !important;
}

/*
THEME COLOR DISPLAY
cc2-aside-panel-theme-ul-li-color
*/
.cc2-aside-panel-theme-ul-li-color {
    display: flex;
}
.cc2-aside-panel-theme-ul-li-color span {
    width: 20px;
    height: 46px;
    display: block;
    text-indent: -9999em;
}
.cc2-aside-panel-theme-ul-li-color span:first-child {
    background: #260101;
}
.cc2-aside-panel-theme-ul-li-color span:nth-child(2) {
    background: #f27405;
}
.cc2-aside-panel-theme-ul-li-color span:last-child {
    background: #f2bb16;
}

/* .pillow .flex {
    outline: blue solid 1px;
} */
.pillow textarea {
    width: 100%;
    min-height: 10px;
    border: 0;
    outline: 0;
    padding: 0;
    /* overflow: hidden; */
    resize: none;
    display: inherit;
}

.pillow input {
    width: 100%;
    border: 0;
    outline: 0;
    padding: 0;
    display: inherit;
    /* min-height: 100px; */
    /* overflow: hidden; */
    /* resize: none; */
    height: auto;
}

.pillow input:focus,
.pillow textarea:focus {
    border: 0;
}

.available_color_image {
    /* display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 9px; */
    /* padding: 10px; */

    padding-left: 3px;
    font-size: 11px !important;
    width: 310px;
    /* min-height: 57px; */

    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px 0px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    line-height: 0;
}

.available_color_image > img {
    width: 12px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.available_color_image img {
    margin-left: 6px;
    margin-bottom: 6px;
}

.available_color_image::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
}

.wfull {
    width: 928px;
}

.w350 {
    max-width: 356px;
    width: 356px;
    color: #fff;
}

.short_description_text {
    width: 275px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.short_description_text > textarea {
    font-size: inherit !important;
}
.short_descript_text textarea {
    font-size: 16px;
}

.long_description_text {
    /* width: 449.03px; */
    /* height: 184px; */
    /* P
    CC / Text
    */
    font-style: normal;
    font-weight: 325;
    font-size: 11px;
    line-height: 130%;
    /* or 16px */
    /* Neutral greys/White */
    color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}
.long_description_text textarea {
    font-family: inherit;
    font-size: inherit;
}

.green-point-cc {
    font-size: 9px;
    /* width: 78px; */
    min-width: 91px;
    height: 34px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0px 0px 14.4384px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 14.4384px rgba(0, 0, 0, 0.25);
    border-radius: 17px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    -webkit-print-color-adjust: exact;
    -webkit-filter: opacity(1);
}
.green-point-cc img {
    width: 22px;
    height: 22px;
    position: absolute;
}

.green-number {
    width: 22px;
    height: 22px;
    border-radius: 11px;
    margin-right: 12px;
    background: #ffffff;
    border: 2px solid #aad0c0;
    border: 1px solid #4a9d7d;
    color: #4a9d7d;
    position: relative;
    left: 14px;
}

.green-title {
    width: 36px;
    /* height: 26px; */
    font-size: 9px;
    /* Secondary/PFC Fresh Green */
    color: #4a9d7d;
    position: relative;
    right: -5px;
}

.available-color {
    /* width: 182px; */
    /* height: 23px; */
    /* H3
    CC / Label
    */
    font-style: normal;
    font-weight: 450;
    line-height: 130%;
    /* or 16px */
    /* Neutral greys/White */
    color: #ffffff;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    padding-left: 6px;
}
.available-color .text-bold_small {
    font-size: 11px;
}
.text-uppercase input {
    text-transform: uppercase;
}
.debtorLogo {
    width: 100px;
    height: 50px;
    border-radius: 4px;
    overflow: hidden;
}

.elementActive,
.hoverFormELe:hover {
    outline: 1px dashed #fff;
}

/* DEBTOR LOGO */
.action-wrap:hover {
    outline: 2px solid #f59d08;
}

.action-wrap:hover .action-box {
    display: block;
}

.action-box {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 2px solid rgba(0, 0, 0, 0.2);
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    width: 56px;
    height: 27px;
    background: #fff;
    padding: 0 6px;
}

.action-box button {
    width: 17px;
    height: 25px;
}

.action-box button:hover {
    background: none;
}

.action-box button:first-child {
    margin-right: 8px;
}

.pillow-margin-backgorund {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 4px 0 0;
    top: 482px;
    height: 40px;
    position: absolute;
    /* width: 280px; */
    /* width: 180px; */
}

@media print {
    /* .ground {
        height: 521px;
    } */
    /* .cc { */
    /* overflow: hidden; */
    /* border: 2px solid red; */
    /* } */
    .pillow-margin-backgorund {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px 0 0;
        /* bottom: -3px; */
        /* top: 482px; */
        height: 40px;
        position: absolute;
        margin-right: 0px;
        /* border: 1px solid blue; */
        /* width: 280px; */
        /* width: 180px; */
    }
}

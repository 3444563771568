.overlay_wrap {
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1003;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
}
.setting__element__color__bg {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 24px;
  width: 24px;
  /* 20px; */
  height: 24px;
  /* 20px; */
  background: #000;
  cursor: pointer;
  border: 1px solid #000;
  margin: 0px;
  box-sizing: border-box;
}

.setting__element__color__bg.selected:before {
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px white solid;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.gap10 {
  gap: 10px;
}

.modal_title,
.backgroundBox__title {
	box-sizing: content-box;
	display: flex;
	justify-content: space-between;
	/* background-color: red; */
	border-bottom: 1px #cccccc solid;
	height: 54px;
	line-height: 54px;
	/* box-sizing: border-box; */
}

.backgroundBox__title button:hover,
.backgroundBox__title button:active {
	background: transparent !important;
}
.modal_title h4,
.backgroundBox__title h4 {
	font-size: 18px;
	font-family: "Conv_Gotham-Book", Verdana, Arial, sans-serif;
	margin: 10px 0;
	color: #000;
	margin: 0;
	line-height: 54px;
}

.modal_title button,
.backgroundBox__title button {
	/* background: red; */
	width: 16px;
	height: 16px;
	position: relative;
	right: -10px;
	top: 10px;
}

.modal_title img,
.backgroundBox__title img {
	width: 16px;
	position: absolute;
	top: 0;
	right: 0;
}
div.pt20 {
    padding: 20px 0 0;
}

div.pt25 {
    padding: 25px 0 0;
}

#app_wrapper {
    height: 100%;
    min-height: 625px;
    font-family: 'Conv_Gotham-Book', Verdana, Arial, sans-serif;
    /* max-width: 1134px; */
    min-width: 980px;
    margin: 0 auto;
}

#app_wrapper.other-page button:hover {
    border: 0;
}

#app_wrapper .title {
    /* outline: 1px dashed green; */
    flex: 0 0 100px;
}

#app_wrapper .draw {
    /* outline: 1px dashed orange; */
    flex: 0 0;
    height: 486px;
    width: 1134px;
    /* height: 550px; */
    height: 605px;
    -webkit-box-shadow: 0px 6px 15px 0 rgb(82 82 82 / 28%);
    -moz-box-shadow: 0px 6px 15px 0 rgb(82 82 82 / 28%);
    box-shadow: 0px 6px 15px 0 rgb(82 82 82 / 28%);
}

/* #app_wrapper>div>aside {
  outline: 1px dashed blue;
} */

.draw {
    /* height: calc(100% - 50px); */
    width: 100%;
    /* width: 1136px; */
    background: #fff;
    margin: 0 auto;
}

.draw aside {
    flex: 0 0 182px;
    max-width: 182px;
}

#fontFamily .cc *,
#fontFamily p {
    font-family: inherit;
    margin: inherit;
}

.draw > div {
    flex: 1;
    width: 952px;
    padding: 12px;
    /* overflow-x: auto; */
    /* align-items: center; */
    height: 100%;
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
    /* padding-left: 2px; */
    position: relative;
    /* top: 66px; */
}
/* .main.board {
    padding-top: 68px;
    z-index: 1;
    box-shadow: 0px 6px 15px #00000048;
} */

/* .draw section {
  min-width: 1200px;
} */

.unauthorized {
    height: 100%;
    font-size: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loaderWrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1020;
    align-items: center;
    background: rgba(0, 0, 0, 0.3);
}

.loader {
    border: 4px solid #fff;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.6s linear infinite;
    position: absolute;
    z-index: 1000;
    /* box-shadow: 0 0 10px #000; */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#testID .backgroundimage:hover::after {
    border: 0;
}

.breakAll {
    word-break: break-all;
}
